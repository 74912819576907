type MoreInfoTriggerProps = {
	children: React.ReactNode
	selected: boolean
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

const UNSELECTED_CLASSNAMES =
	"p-2 m-2 border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-100"

const SELECTED_CLASSNAMES =
	"p-2 m-2 border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-100 bg-gray-100"

export const MoreInfoTrigger: React.FC<MoreInfoTriggerProps> = ({
	children,
	selected,
	onClick,
}) => {
	const classNames = selected ? SELECTED_CLASSNAMES : UNSELECTED_CLASSNAMES

	return (
		<div className={classNames} onClick={onClick}>
			{children}
		</div>
	)
}
