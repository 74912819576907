import { Navigate, Outlet } from "react-router-dom"
import { SignedIn, SignedOut, useAuth, useUser } from "@clerk/clerk-react"
import NavDrawer from "../Components/NavDrawer"
import { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink } from "@trpc/client"
import trpc from "../trpc"
import { OrganizationContextProvider } from "../Contexts"
import { usePostHog } from "posthog-js/react"

const LoggedInLayout: React.FC = () => {
	const { getToken /*isLoaded, isSignedIn */ } = useAuth()

	const posthog = usePostHog()
	const user = useUser()

	useEffect(() => {
		if (!user.isSignedIn) return
		posthog.identify(user.user.id, user.user)
	}, [user, posthog])

	const [queryClient] = useState(() => new QueryClient())
	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpBatchLink({
					url: `${process.env.REACT_APP_BACKEND_TRPC_ENDPOINT}`,
					// You can pass any HTTP headers you wish here
					async headers() {
						const token = await getToken()
						if (!token) {
							console.error("No token found")
							return {}
						}
						return {
							authorization: token,
						}
					},
				}),
			],
		}),
	)

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<OrganizationContextProvider>
					<main>
						<SignedIn>
							<div className="flex flex-row h-screen w-screen">
								<NavDrawer />
								<div className="h-full overflow-y-auto flex-grow">
									<Outlet />
								</div>
							</div>
						</SignedIn>
						<SignedOut>
							<div className="flex flex-col items-center justify-center h-screen">
								<h1 className="text-4xl font-bold">
									Redirecting to sign in...
								</h1>
							</div>
							<Navigate to="/sign-in" />
						</SignedOut>
					</main>
				</OrganizationContextProvider>
			</QueryClientProvider>
		</trpc.Provider>
	)
}

export default LoggedInLayout
