import { WorkingCapitalTable } from "./types"

type DPODatapoint = {
	DPO: number
}

/**
 * Calculates DSO for each period except the first 6
 * The first 6 are not included because they are used
 * for filling in missing data
 *
 * @param table
 * @returns
 */
export const calculateDPO = (table: WorkingCapitalTable): DPODatapoint[] => {
	const output: DPODatapoint[] = []
	for (let i = 6; i < table.columns.length; i++) {
		const column = table.columns[i]

		const APEndingCell = table.APEndBalanceRow.data[i]
		const APStartCell = table.APEndBalanceRow.data[i - 1]
		const APAverageLast2Periods = (APStartCell.value + APEndingCell.value) / 2

		const cogsCell = table.cogsRow.data[i]
		let cogsValue = cogsCell.value
		if (cogsValue <= 0) {
			//can't divide by 0
			//fix the gap with the average of the last 6 periods
			cogsValue = 0
			for (let j = i - 6; j < i; j++) {
				cogsValue += table.creditSalesRow.data[j].value
			}
			cogsValue /= 6
		}

		const startDate = new Date(column.startDate)
		const endDate = new Date(column.endDate) //end date is exclusive

		//get the number of days in the period
		const daysInPeriod =
			(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

		const DPO = (APAverageLast2Periods / cogsValue) * daysInPeriod

		output.push({
			DPO: DPO,
		})
	}
	return output
}

export const calculateDPOForAllTable = (table: WorkingCapitalTable): number => {
	const lastPeriodIndex = table.columns.length - 1
	const APEndingCell = table.APEndBalanceRow.data[lastPeriodIndex]

	let cogsDuringPeriod = 0
	for (let i = 0; i < table.columns.length; i++) {
		const cogsCell = table.cogsRow.data[i]
		cogsDuringPeriod += cogsCell.value
	}

	const startDate = new Date(table.columns[lastPeriodIndex].startDate)
	const endDate = new Date(table.columns[lastPeriodIndex].endDate)
	const daysInPeriod =
		(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

	return (APEndingCell.value / cogsDuringPeriod) * daysInPeriod
}
