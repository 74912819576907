import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import MoreInfoSelector from "../../../Components/MoreInfoSelector"
import { WorkingCapitalMetricsLineChart } from "./WorkingCapitalMetricsLineChart"
import { KeyTakeaways, TheMetricsText } from "./WorkingCapitalMetricsText"

type WorkingCapitalMetricsSectionProps = {
	options: {
		startDate: Date
		endDate: Date
	}
}

export const WorkingCapitalMetricsSection: React.FC<
	WorkingCapitalMetricsSectionProps
> = ({ options }) => {
	const moreInfoOptions = [
		{
			trigger: <span className="font-bold">The Metrics</span>,
			content: <TheMetricsText />,
		},
		{
			trigger: <span className="font-bold">Key Takeaways</span>,
			content: <KeyTakeaways />,
		},
	]

	return (
		<Card className="h-full">
			<CardHeader>
				<CardTitle>
					Working Capital Metrics and Their Impact on Liquidity
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col items-center justify-center space-y-4 w-full">
					<WorkingCapitalMetricsLineChart options={options} />
					<MoreInfoSelector options={moreInfoOptions} />
				</div>
			</CardContent>
		</Card>
	)
}
