import { createBrowserRouter, RouterProvider } from "react-router-dom"

import {
	HomePage,
	SignInPage,
	SignUpPage,
	OnboardPage,
	QuickbooksCallback,
	TermsAndConditionsPage,
	PrivacyPolicyPage,
	UserCreationPage,
	IntegrationsPage,
} from "./Pages"

import { RootLayout, LoggedInLayout } from "./Layouts"

import { posthog } from "posthog-js"

const router = createBrowserRouter([
	{
		element: <RootLayout />,
		children: [
			{ path: "/sign-in/*", element: <SignInPage /> },
			{ path: "/sign-up/*", element: <SignUpPage /> },
			{ path: "/terms-and-conditions", element: <TermsAndConditionsPage /> },
			{ path: "/privacy-policy", element: <PrivacyPolicyPage /> },
			{
				element: <LoggedInLayout />,
				children: [
					{ path: "/", element: <HomePage /> },
					{ path: "/onboarding", element: <OnboardPage /> },
					{ path: "/quickbooks-callback", element: <QuickbooksCallback /> },
					{ path: "/user-creation", element: <UserCreationPage /> },
					{ path: "/integrations", element: <IntegrationsPage /> },
					{ path: "/quickbooks-callback", element: <QuickbooksCallback /> },
				],
			},
		],
	},
])

const App: React.FC = () => {
	posthog.capture("Frontend Loaded")

	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	)
}

export default App
