import { Loader2 } from "lucide-react"
import trpc from "../../trpc"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export const UserCreation = () => {
  const createUser = trpc.createUser.useMutation({
    onSuccess: () => {
      navigate("/onboarding")
    },
    onError: (error) => {
      if(error.message === "User already exists"){
        navigate("/onboarding")
      }
      console.error(error)
    },
  })

  const navigate = useNavigate()

  useEffect(() => {
    createUser.mutate({})
  })


  return(
    <div className="flex flex-col items-center justify-center space-y-4 h-screen">
      
      {createUser.isError?
        <span className="text-red-500">
          {createUser.error.message}
        </span>
        :
        <>
          <Loader2
            className="animate-spin text-gray-500"
          />
          <span>Creating your account...</span>
        </>
      }
    </div>
  )
}