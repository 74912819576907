import {
  ChevronDownIcon,
  ChevronRightIcon
} from "lucide-react"


type ToggleChevronProps = {
  isOpen: boolean,
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const ToggleChevron:React.FC<ToggleChevronProps> = ({
  isOpen,
  onClick
}) => {
  if(!isOpen) {
    return (
      <ChevronRightIcon
        className="w-4 h-4 ml-2 text-gray-500"
        onClick={onClick}
      />
    )
  }
  return (
    <ChevronDownIcon
      className="w-4 h-4 ml-2 text-gray-500"
      onClick={onClick}
    />
  )
}