import { useMemo } from "react"
import { TableCell, TableRow } from "../Table"
import { DataCell } from "./DataCell"
import { twMerge } from "tailwind-merge"
import { RowNameCellContent } from "./RowNameCellContent"

import { TableState, Column, RowData, DataPoint } from "./types"

export type RowStyle = {
	stickyOverlayTags?: string
	rowTags?: string
	cellTags?: string
	firstCellTags?: string
	lastCellTags?: string
	headerCellTags?: string
}

type DataTableRowProps = {
	row: RowData
	tableState: TableState
	columns: Column[]
	indent?: number
	rowStyle?: RowStyle
	childRowStyle?: RowStyle
	onCellEdit: (
		datapoint: DataPoint,
		row: RowData,
		column: Column,
		value: number,
	) => void
	onRowOpen: (rowName: string) => void
	onRowClose: (rowName: string) => void
	blockAdditionalInfo?: boolean
}

export const DataTableRow: React.FC<DataTableRowProps> = ({
	row,
	tableState,
	columns,
	indent = 0,
	rowStyle = {},
	childRowStyle = {},
	onRowOpen,
	onRowClose,
	onCellEdit,
	blockAdditionalInfo = false,
}) => {
	const isOpen = useMemo(() => {
		return tableState.options.openRows.includes(row.path)
	}, [tableState.options.openRows, row.path])

	return (
		<>
			<TableRow className={rowStyle.rowTags}>
				<TableCell sticky stickyOverlayTags={rowStyle.stickyOverlayTags} left>
					{row.root ? (
						<RowNameCellContent
							row={row}
							indent={indent}
							className={rowStyle.headerCellTags}
						/>
					) : (
						<RowNameCellContent
							row={row}
							indent={indent}
							chevron
							chevronOpen={isOpen}
							onChevronClick={() => {
								if (isOpen) {
									onRowClose(row.path)
								} else {
									onRowOpen(row.path)
								}
							}}
							className={rowStyle.headerCellTags}
						/>
					)}
				</TableCell>
				{row.data.map((dataPoint, index) => {
					const classNames = [rowStyle.cellTags]
					if (index === 0) {
						classNames.push(rowStyle.firstCellTags)
					} else if (index === row.data.length - 1) {
						classNames.push(rowStyle.lastCellTags)
					}
					const cellClassName = twMerge(...classNames)
					return (
						<DataCell
							dataPoint={dataPoint}
							tableState={tableState}
							row={row}
							column={columns[index]}
							showAdditionalInfo={!blockAdditionalInfo}
							cellClassName={cellClassName}
							onEdit={(newValue) => {
								onCellEdit &&
									onCellEdit(dataPoint, row, columns[index], newValue)
							}}
						/>
					)
				})}
			</TableRow>
			{!row.root &&
				isOpen &&
				row.children.map((childRow) => (
					<DataTableRow
						key={childRow.name}
						row={childRow}
						tableState={tableState}
						columns={columns}
						indent={indent + 1}
						rowStyle={childRowStyle}
						onRowOpen={onRowOpen}
						onRowClose={onRowClose}
						onCellEdit={onCellEdit}
					/>
				))}
		</>
	)
}
