import { useContext } from "react"
import { OrganizationContext } from "../../../Contexts"
import trpc from "../../../trpc"
import {
	Bill,
	BillAttachment,
	ReasonForInclusion,
} from "../../../../../backend/src/Quickbooks"

type ContactVendorViewProps = {
	bill: Bill
}

const ContactVendorView: React.FC<ContactVendorViewProps> = ({ bill }) => {
	const [organizationContext] = useContext(OrganizationContext)
	const { data } = trpc.getVendor.useQuery(
		{
			organizationId: organizationContext.organization?.id || -1,
			vendorId: bill.VendorRef.value,
		},
		{
			enabled: !!organizationContext.organization,
			suspense: true,
		},
	)

	if (!data) {
		return <p className="text-red-500">No data</p>
	}

	const { result: vendor, error: vendorError } = data

	if (vendorError) {
		return <p>Error: {vendorError.message}</p>
	}

	return (
		<div className="flex flex-row items-center justify-start space-x-1 flex-wrap">
			<span>Contact Vendor: </span>
			<span>{bill.VendorRef.name}</span>
			{vendor.PrimaryEmailAddr && (
				<span>
					<a
						href={`mailto:${vendor.PrimaryEmailAddr.Address}`}
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 underline"
					>
						{vendor.PrimaryEmailAddr.Address}
					</a>
				</span>
			)}
			{vendor.PrimaryPhone && (
				<span>
					<a
						href={`tel:${vendor.PrimaryPhone.FreeFormNumber}`}
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 underline"
					>
						{vendor.PrimaryPhone.FreeFormNumber}
					</a>
				</span>
			)}
		</div>
	)
}

type ReasonForInclusionViewProps = {
	reasonForInclusion: ReasonForInclusion
}

const ReasonForInclusionView: React.FC<ReasonForInclusionViewProps> = ({
	reasonForInclusion,
}) => {
	if (reasonForInclusion.reason === "Due") {
		return <span>Bill is due</span>
	}
	if (reasonForInclusion.reason === "Overdue") {
		return <span className="text-red-500">Bill is overdue</span>
	}
	if (reasonForInclusion.reason === "Payment Made") {
		return <span>Payment made</span>
	}
	return <span>Unknown reason for inclusion</span>
}

type BillViewProps = {
	billAttachment: BillAttachment
	className?: string
}

export const BillView: React.FC<BillViewProps> = ({
	billAttachment,
	className,
}) => {
	const [organizationContext] = useContext(OrganizationContext)
	const { data, isLoading, error } = trpc.getBill.useQuery(
		{
			organizationId: organizationContext.organization?.id || -1,
			billId: billAttachment.BillRef.value,
		},
		{
			enabled: !!organizationContext.organization,
			suspense: true,
		},
	)

	if (error) {
		return <p>Error: {error.message}</p>
	}
	if (isLoading) {
		return <p>Loading...</p>
	}
	if (!data) {
		return <p>No data</p>
	}
	const { result: bill, error: billError } = data
	if (billError) {
		return <p>Error: {billError.message}</p>
	}

	const overdue = billAttachment.reasonForInclusion.reason === "Overdue"

	return (
		<div className="flex flex-col items-start">
			{bill.VendorRef?.name && <span>{bill.VendorRef.name}</span>}
			<div className="flex flex-row items-start space-x-1">
				<span>Total: </span>
				<span>{bill.TotalAmt!}</span>
			</div>
			<div className="flex flex-row items-start space-x-1">
				<span>Balance Due: </span>
				<span>{bill.Balance!}</span>
			</div>
			<div>
				<span>Due Date: </span>
				<span className={overdue ? "text-red-500" : "text-gray-500"}>
					{bill.DueDate!}
				</span>
			</div>
			<div>
				<span>Reason for Inclusion: </span>
				<ReasonForInclusionView
					reasonForInclusion={billAttachment.reasonForInclusion}
				/>
			</div>
			<ContactVendorView bill={bill} />
		</div>
	)
}
