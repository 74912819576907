/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import {
	OrganizationContext,
	OrganizationContextType,
} from "./OrganizationContext"
import trpc from "../trpc"

type inputType = {
	children: React.ReactNode
}

/**
 * Provides the organization context to the children.
 * This component also retrieves the organizations from the backend and sets the context.
 * It does the same with the integration status.
 * @returns
 */
export const OrganizationContextProvider: React.FC<inputType> = ({
	children,
}) => {
	const [organizationContext, setOrganizationContext] =
		useState<OrganizationContextType>({
			organization: null,
		})

	const getOrganizationsQuery = trpc.getUsersOrganizations.useQuery(
		{},
		{
			enabled: organizationContext.organization === null,
		},
	)

	const getIntegrationStatusQuery = trpc.getIntegrationStatus.useQuery(
		{
			organizationId: organizationContext.organization?.id ?? -1,
		},
		{
			enabled: organizationContext.organization !== null,
		},
	)

	useEffect(() => {
		if (
			organizationContext.organization === null &&
			getOrganizationsQuery.data !== undefined &&
			getOrganizationsQuery.data.length > 0
		) {
			const integrationStatus = getIntegrationStatusQuery.data ?? []
			setOrganizationContext({
				organization: getOrganizationsQuery.data[0],
				integrationStatus: [...integrationStatus],
			})
		}
		if (
			organizationContext.organization !== null &&
			organizationContext.integrationStatus.length === 0 &&
			(getIntegrationStatusQuery.data === undefined ||
				getIntegrationStatusQuery.data.length === 0)
		) {
			getIntegrationStatusQuery.refetch()
		}
	}, [
		organizationContext.organization,
		getOrganizationsQuery.data,
		getIntegrationStatusQuery.data,
	])

	useEffect(() => {
		if (
			organizationContext.organization !== null &&
			organizationContext.integrationStatus.length === 0 &&
			getIntegrationStatusQuery.data !== undefined &&
			getIntegrationStatusQuery.data.length > 0
		) {
			setOrganizationContext((organizationContext) => ({
				...organizationContext,
				integrationStatus: [...getIntegrationStatusQuery.data],
			}))
		}
	}, [getIntegrationStatusQuery.data, organizationContext])

	return (
		<OrganizationContext.Provider
			value={[organizationContext, setOrganizationContext]}
		>
			{children}
		</OrganizationContext.Provider>
	)
}
