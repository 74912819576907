import { SignIn } from "@clerk/clerk-react"

const SignInPage:React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <SignIn
        path="/sign-in"
        signUpUrl="/sign-up"
      />
    </div>
  )
}

export default SignInPage