import { IntegrationStatus } from "src/@/types"
import { ReactComponent as QuickbooksLogo } from "../../Assets/QuickbooksLogo.svg"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { IntegrationModalContent } from "./IntegrationModalContent"

type IntegrationViewProps = {
  integrationStatus: IntegrationStatus[0]
  type: "Quickbooks"
  onDisconnect?: () => void
}

export const IntegrationView:React.FC<IntegrationViewProps> = ({
  integrationStatus,
  type,
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div
          className={
            `flex flex-row justify-center align-center space-x-4
            cursor-pointer
            bg-gray-200 hover:bg-gray-300 text-gray-500 hover:text-gray-700
            p-4 rounded-lg`
          }
        >
          <QuickbooksLogo
            className="w-32"
          />
          <span
            className="whitespace-nowrap"
          >
            {integrationStatus.name}
          </span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {integrationStatus.name}
          </DialogTitle>
        </DialogHeader>
        <IntegrationModalContent
          integrationStatus={integrationStatus}
          type={type}
        />
      </DialogContent>
    </Dialog>
  )
}