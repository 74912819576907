import {
	AccordionComponentMarkdown,
	AccordionMarkdownDataType,
} from "../../../Components/ShadcnPlainComponents"

export const UnderstandingCurrentRatio = () => {
	const UnderstandingCurrentRatioAccordionProps: AccordionMarkdownDataType[] = [
		{
			title: "Current Assets",
			markdownContent:
				"Typically include cash, accounts receivable, inventory, and other assets that are expected to be converted to cash within a year.",
		},
		{
			title: "Current Liabilities",
			markdownContent:
				"Typically include accounts payable, short-term debt, and other obligations due within a year.",
		},
	]

	return (
		<div className="w-full">
			<h2 className="text-2xl font-bold mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
				Understanding the Current Ratio
			</h2>
			<p>
				Current Ratio is a financial metric that measures a company's ability to
				pay off its short-term liabilities with its short-term assets.
			</p>
			<AccordionComponentMarkdown
				data={UnderstandingCurrentRatioAccordionProps}
			/>
		</div>
	)
}

export const WhatTheCurrentRatioIndicates = () => {
	const WhatTheCurrentRatioIndicatesAccordionProps: AccordionMarkdownDataType[] =
		[
			{
				title:
					"You generally want a current ratio that is high enough to indicate good liquidity but not excessively high.",
				markdownContent:
					"" +
					"- **Explanation**: A high current ratio indicates that the company has more than enough current assets to cover its current liabilities.\n" +
					"- **Implication**: This is generally seen as a sign of good liquidity and financial health, suggesting the company can comfortably meet its short-term obligations.\n" +
					"However, an excessively high current ratio may indicate that the company is not efficiently using its assets, potentially tying up too much cash in inventory or accounts receivable rather than investing it in growth opportunities.\n",
			},
			{
				title:
					"A current ratio in the range of 1.2 to 2.0 is typically seen as healthy, depending on the industry and specific business context.",
				markdownContent:
					"" +
					"- **Explanation**: A current ratio in the range of 1.2 to 2.0 is typically seen as healthy, depending on the industry and specific business context.\n" +
					"- **Implication**: This can be a sign of potential liquidity problems, suggesting the company might struggle to meet its short-term obligations. A very low current ratio could indicate financial distress or that the company is overly reliant on short-term debt.\n",
			},
		]

	return (
		<div className="w-full">
			<h2 className="text-2xl font-bold mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
				What the Current Ratio Indicates
			</h2>
			<AccordionComponentMarkdown
				data={WhatTheCurrentRatioIndicatesAccordionProps}
			/>
		</div>
	)
}

export const CurrentRatioKeyTakeaways = () => {
	const CurrentRatioKeyTakeawaysAccordionProps: AccordionMarkdownDataType[] = [
		{
			title: "You set this the value of",
			markdownContent:
				"" +
				"- The company’s current ratio is a key metric for determining the company’s liquidity and ability to meet short-term obligations.\n" +
				"- A higher current ratio indicates a higher level of liquidity and better financial health.\n" +
				"- A lower current ratio may indicate a higher level of debt and potential liquidity problems.\n",
		},
		{
			title:
				"A current ratio of 1.2 or higher is generally considered healthy.",
			markdownContent:
				"" +
				"- A current ratio of 1.2 or higher is generally considered healthy.\n" +
				"- This is a good indication that the company has sufficient liquidity to cover its short-term obligations.\n" +
				"- However, it is important to note that a current ratio of 1.2 or higher may not always be a sign of excessive liquidity.\n" +
				"- It is important to regularly review and adjust the current ratio to ensure it remains healthy and appropriate for the company's specific needs.\n",
		},
		{
			title:
				"A current ratio of 1.0 or lower is generally considered unhealthy.",
			markdownContent:
				"" +
				"- A current ratio of 1.0 or lower is generally considered unhealthy.\n" +
				"- This is a sign that the company may be over-reliant on short-term debt and may struggle to meet its short-term obligations.\n" +
				"- It is important to regularly review and adjust the current ratio to ensure it remains healthy and appropriate for the company's specific needs.\n",
		},
	]

	return (
		<div className="w-full">
			<h2 className="text-2xl font-bold mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
				Key Takeaways
			</h2>
			<AccordionComponentMarkdown
				data={CurrentRatioKeyTakeawaysAccordionProps}
			/>
		</div>
	)
}
