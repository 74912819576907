import { ToggleChevron } from "../ToggleChevron"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { MarkdownRenderer } from "../MarkdownRenderer"
import { HelpIcon } from "../HelpIcon"
import { RowData } from "./types"
import { twMerge } from "tailwind-merge"

type RowNameCellContentProps = {
  className?: string
  row: RowData,
  indent: number,
  chevron?: boolean
  chevronOpen?: boolean
  onChevronClick?: (arg: React.MouseEvent<SVGSVGElement>) => void
}

//in pixels
const INDENT_SIZE = 10

const INDENT_IF_MISSING_CHEVRON = 28

/**
 * The content for the leftmost cell in a row
 * 
 * @param row the row to display
 * @param indent the indent level of the row
 * @param chevron whether to display a chevron
 * @param chevronOpen  whether the chevron is open
 * @param onChevronClick the callback to call when the chevron is clicked
 * 
 * @returns 
 */
export const RowNameCellContent:React.FC<RowNameCellContentProps> = ({
  className,
  row,
  indent,
  chevron = false,
  chevronOpen = false,
  onChevronClick
}) => {
  let indentSize = indent * INDENT_SIZE
  if(indent>0 && !chevron) {
    indentSize += INDENT_IF_MISSING_CHEVRON
  }

  const rowNameToDisplay = row.alias || row.name

  const mergedClassName = twMerge("flex flex-row items-center justify-start space-x-2", className)

  return (
    <div
      className={mergedClassName}
      style={{
        paddingLeft: `${indentSize}px`,
      }}
    >
      {chevron &&
        <ToggleChevron
          isOpen={chevronOpen}
          onClick={onChevronClick}
        />
      }
      <div
        className="flex-grow flex flex-row items-center justify-start"
      >
        <span>
          {rowNameToDisplay}
        </span> 
        {row.description &&
          <Dialog>
            <DialogTrigger asChild>
              <HelpIcon
                className="ml-2"
              />
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>
                  {rowNameToDisplay}
                </DialogTitle>
              </DialogHeader>
              <div
                className="flex flex-col justify-start items-stretch max-h-[60vh] overflow-y-scroll"
              >
                <MarkdownRenderer
                  markdown={row.description}
                />
              </div>
            </DialogContent>
          </Dialog>
        }
      </div>
    </div>
  )
}