import React from "react";
import { IntegrationStatus, Organization } from "../types";

type NoOrganizationType = {
  organization: null,
}

type WithOrganizationType = {
  organization: Organization,
  integrationStatus: IntegrationStatus
}

export type OrganizationContextType = 
  | NoOrganizationType
  | WithOrganizationType

export const OrganizationContext = React.createContext<
  [
    OrganizationContextType,
    React.Dispatch<React.SetStateAction<OrganizationContextType>>
  ]
>([{organization:null}, () => {}]);