import { WorkingCapitalTable } from "./types"
import trpc from "../../trpc"
import { useContext } from "react"
import { OrganizationContext } from "../../Contexts"
import { TableTimePeriodOptions } from "../../../../backend/src/BusinessLogic/Table"

type useWorkingCapitalTableProps = {
	tableOptions: TableTimePeriodOptions
}

type useWorkingCapitalTableReturn =
	| {
			isLoading: true
			table: null
			error: null
			refetch: () => void
	  }
	| {
			isLoading: false
			table: WorkingCapitalTable
			error: null
			refetch: () => void
	  }
	| {
			isLoading: false
			table: null
			error: string
			refetch: () => void
	  }

const globalJobPromise: { promise: Promise<string> | null } = { promise: null }

/**
 * A hook that fetches the working capital table
 *
 */
export const useWorkingCapitalTable = ({
	tableOptions,
}: useWorkingCapitalTableProps): useWorkingCapitalTableReturn => {
	const [organizationContext] = useContext(OrganizationContext)

	const organizationId = organizationContext.organization?.id

	const { data, isLoading, error, refetch } =
		trpc.getWorkingCapitalTable.useQuery(
			{
				organizationId: organizationId || -1,
				tableOptions: {
					startDate: tableOptions.startDate.toISOString(),
					endDate: tableOptions.endDate.toISOString(),
					frequency: tableOptions.frequency,
				},
			},
			{
				enabled: !!organizationId,
			},
		)

	const buildWorkingCapitalModel = trpc.buildWorkingCapitalModel.useMutation()

	if (!organizationId) {
		return {
			isLoading: false,
			table: null,
			error: "No organization selected",
			refetch: refetch,
		}
	}

	if (isLoading) {
		return {
			isLoading: true,
			table: null,
			error: null,
			refetch: refetch,
		}
	}

	if (error) {
		return {
			isLoading: false,
			table: null,
			error: error.message,
			refetch: refetch,
		}
	}

	if (!data) {
		//this shouldn't happen
		return {
			isLoading: false,
			table: null,
			error: "No data found",
			refetch: refetch,
		}
	}

	const { table, error: returnedError } = data

	if (returnedError === "MULTIPLE_WORKING_CAPITAL_MODELS_FOUND") {
		return {
			isLoading: false,
			table: null,
			error: "Multiple working capital models found",
			refetch: refetch,
		}
	}
	if (returnedError === "WORKING_CAPITAL_MODEL_NOT_FOUND") {
		if (buildWorkingCapitalModel.isPending || globalJobPromise.promise) {
			return {
				isLoading: true,
				table: null,
				error: null,
				refetch: refetch,
			}
		} else {
			//create a new working capital model
			globalJobPromise.promise = buildWorkingCapitalModel.mutateAsync({
				organizationId,
			})

			//refetch after 5 seconds
			setTimeout(() => {
				refetch()
			}, 5000)
			return {
				isLoading: true,
				table: null,
				error: null,
				refetch: refetch,
			}
		}
	}
	if (returnedError === "WORKING_CAPITAL_MODEL_JOB_IN_PROGRESS") {
		//refetch after 5 seconds
		setTimeout(() => {
			refetch()
		}, 5000)
		return {
			isLoading: true,
			table: null,
			error: null,
			refetch: refetch,
		}
	}
	if (returnedError) {
		return {
			isLoading: false,
			table: null,
			error: returnedError,
			refetch: refetch,
		}
	}

	if (!table) {
		return {
			isLoading: false,
			table: null,
			error: "No table found",
			refetch: refetch,
		}
	}

	return {
		isLoading: false,
		table: table,
		error: null,
		refetch: refetch,
	}
}
