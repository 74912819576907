import { useContext } from "react"
import { OrganizationContext } from "../../Contexts"
import { useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { IntegrationView } from "./IntegrationView"

export const IntegrationsPageContent = () => {
	const [organizationContext] = useContext(OrganizationContext)

	const navigate = useNavigate()

	if (!organizationContext.organization) {
		return (
			<div className="flex flex-row items-center justify-center h-full">
				No organization selected
				<Button
					onClick={() => {
						navigate("/onboarding")
					}}
				>
					Go to onboarding
				</Button>
			</div>
		)
	}

	return (
		<div className="flex flex-col items-center justify-center h-full">
			<div>My Integrations</div>
			<div className="flex justify-center items-center w-full p-16">
				<div className="flex flex-row flex-wrap justify-start bg-gray-100 p-4 rounded-lg w-full">
					{organizationContext.integrationStatus.length === 0 && (
						<div>No integrations connected</div>
					)}
					{organizationContext.integrationStatus.map((integrationStatus) => (
						<IntegrationView
							key={integrationStatus.id}
							integrationStatus={integrationStatus}
							type="Quickbooks"
						/>
					))}
				</div>
			</div>
		</div>
	)
}
