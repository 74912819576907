
const TermsAndConditions = () => <>
  <div
    className="w-screen h-screen"
  >
    <iframe
      title="Terms and Conditions"
      src="/TermsAndConditions.html"
      width="100%"
      height="100%"
    />
  </div>
</>

export default TermsAndConditions