import { Assertion } from "../types"

type ContactVendorViewProps = {
  assertion: Assertion
  rowName: string
}

export const AssertionView:React.FC<ContactVendorViewProps> = ({
  assertion,
  rowName
}) => {
  const startDate = new Date(assertion.startDate)
  const endDate = new Date(assertion.endDate)

  const startDateString = startDate.toLocaleDateString()
  const endDateString = endDate.toLocaleDateString()

  const singleDay = startDate.getDate() === endDate.getDate()

  const targetParts = assertion.target.split("/")
  let targetName = decodeURIComponent(targetParts[targetParts.length-1])
  if(targetName === "known_cash_on_hand") {
    targetName = "Cash on Hand"
  }

  return (
    <div
      className="flex flex-col items-start"
    >
      <div
        className="flex flex-row items-start space-x-1"
      >
        <span>You set this the value of</span> 
        <span className="text-blue-400">
          {targetName}
        </span>
        <span>to</span>
        <span
          className="text-purple-400"
        >
          {assertion.value}
        </span>
      </div>
      {singleDay?
        <span>For {startDateString}</span>
        :
        <span>For the period {startDateString} to {endDateString}</span>
      }
    </div>
  )
}