import { WorkingCapitalTableBuilder } from "../../Components/WorkingCapitalTable"
import { ActionPlanWorkingCapitalManagement } from "./ActionPlanWorkingCapitalManagement"
import { CurrentRatioSection } from "./CurrentRatioSection/CurrentRatioSection"
import WorkingCapitalMetricsSection from "./WorkingCapitalMetricsSection"
import { useState } from "react"
import { Frequency } from "../../../../backend/src/BusinessLogic"
import { StartEndFrequencySelector } from "../../Components/StartEndFrequencySelector"
import { CurrentRatioManagement } from "./CurrentRatioManagement"
import { ImplementationTipsText } from "./ImplementationTipsText"
const today = new Date()
today.setHours(0, 0, 0, 0)

const sixMonthsAgo = new Date(today)
sixMonthsAgo.setMonth(today.getMonth() - 6)

const DEFAULT_FREQUENCY: Frequency = "monthly"

const HomePageContent = () => {
	const [startDate, setStartDate] = useState<Date>(sixMonthsAgo)
	const [endDate, setEndDate] = useState<Date>(today)
	const [frequency, setFrequency] = useState<Frequency>(DEFAULT_FREQUENCY)

	return (
		<div className="flex flex-col items-center justify-start overflow-y-scroll space-y-8 p-4">
			<span className="text-4xl font-bold text-gray-900">
				Understanding My Liquidity
			</span>
			<StartEndFrequencySelector
				startDate={startDate}
				endDate={endDate}
				frequency={frequency}
				onStartDateChange={setStartDate}
				onEndDateChange={setEndDate}
				onFrequencyChange={setFrequency}
			/>
			<div className="flex flex-row items-start justify-center w-full flex-wrap">
				<div className="w-[50%] h-full pr-2">
					<CurrentRatioSection
						options={{
							startDate,
							endDate,
							frequency,
						}}
					/>
				</div>
				<div className="w-[50%] h-full pl-2">
					<WorkingCapitalMetricsSection
						options={{
							startDate,
							endDate,
						}}
					/>
				</div>
			</div>
			<div>
				<WorkingCapitalTableBuilder
					selectedStartEndFrequency={{
						startDate,
						endDate,
						frequency,
					}}
				/>
			</div>
			<div className="flex flex-col items-start justify-center w-full space-y-2 space-y-4">
				<div className="flex flex-row items-start w-full">
					<div className="w-[50%] h-full pr-2">
						<CurrentRatioManagement />
					</div>
					<div className="w-[50%] h-full pl-2">
						<ActionPlanWorkingCapitalManagement />
					</div>
				</div>
				<ImplementationTipsText />
			</div>
		</div>
	)
}

export default HomePageContent
