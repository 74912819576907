import { Frequency } from "../../../backend/src/BusinessLogic"
import { moveDateBack } from "../utils"
import { calculateCCCForAllTable } from "../Components/WorkingCapitalTable"
import { useWorkingCapitalTable } from "../Components/WorkingCapitalTable/useWorkingCapitalTable"

type useTrailingWorkingCapitalOptions = {
	date: Date
	trailingPeriods: number
	frequency: Frequency
}

type TrailingWorkingCapitalReturn = {
	value: number | null
	isLoading: boolean
	error: string | null
}

export const useTrailingWorkingCapital = ({
	date,
	trailingPeriods = 13,
	frequency = "weekly",
}: useTrailingWorkingCapitalOptions): TrailingWorkingCapitalReturn => {
	const endDate = new Date(date)
	const startDate = moveDateBack(date, frequency, trailingPeriods)

	const { table, isLoading, error } = useWorkingCapitalTable({
		tableOptions: {
			startDate: startDate,
			endDate: endDate,
			frequency: frequency,
		},
	})

	if (error) {
		return {
			error: error,
			isLoading: false,
			value: null,
		}
	}

	if (isLoading || !table) {
		return {
			error: null,
			isLoading: true,
			value: null,
		}
	}

	const value = calculateCCCForAllTable(table)
	if (isNaN(value)) {
		return {
			error: null,
			isLoading: false,
			value: 0,
		}
	}

	return {
		value: value,
		isLoading: false,
		error: null,
	}
}
