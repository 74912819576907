import { WorkingCapitalTable } from "./types"

type DIODatapoint = {
	DIO: number
}

/**
 * Calculates DSO for each period except the first 6
 * The first 6 are not included because they are used
 * for filling in missing data
 *
 * @param table
 * @returns
 */
export const calculateDIO = (table: WorkingCapitalTable): DIODatapoint[] => {
	const output: DIODatapoint[] = []
	for (let i = 6; i < table.columns.length; i++) {
		const column = table.columns[i]

		const otherCurrentAssetsRow = table.otherCurrentAssetsRow
		if (otherCurrentAssetsRow.root) {
			//can't find the inventory
			output.push({
				DIO: 0,
			})
			continue
		}

		//compute inventory
		const inventoryRows = otherCurrentAssetsRow.children.filter((row) =>
			row.name.includes("Inventory"),
		)
		let thisPeriodInventorySum = 0
		let lastPeriodInventorySum = 0
		for (const inventoryRow of inventoryRows) {
			const inventoryCell = inventoryRow.data[i]
			const thisPeriodInventoryValue = inventoryCell.value
			const netChangeAttachment = inventoryCell.attachment.find(
				(attachment) => attachment && attachment.type === "NetChange",
			)
			if (!netChangeAttachment) {
				console.error("No NetChangeAttachment found for inventory cell")
				output.push({
					DIO: 0,
				})
				continue
			}
			const lastPeriodInventoryValue =
				thisPeriodInventoryValue - netChangeAttachment.value

			thisPeriodInventorySum += thisPeriodInventoryValue
			lastPeriodInventorySum += lastPeriodInventoryValue
		}

		const inventoryValueToUse =
			(thisPeriodInventorySum + lastPeriodInventorySum) / 2

		const cogsCell = table.cogsRow.data[i]
		let cogsValue = cogsCell.value
		if (cogsValue <= 0) {
			//can't divide by 0
			//fix the gap with the average of the last 6 periods
			cogsValue = 0
			for (let j = i - 6; j < i; j++) {
				cogsValue += table.creditSalesRow.data[j].value
			}
			cogsValue /= 6
			if (cogsValue === 0) {
				cogsValue = 1
			}
		}

		const startDate = new Date(column.startDate)
		const endDate = new Date(column.endDate) //end date is exclusive
		const daysInPeriod =
			(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

		const DIO = (inventoryValueToUse / cogsValue) * daysInPeriod

		output.push({
			DIO: DIO,
		})
	}
	return output
}

export const calculateDIOForAllTable = (table: WorkingCapitalTable): number => {
	const otherCurrentAssetsRow = table.otherCurrentAssetsRow
	if (otherCurrentAssetsRow.root) {
		//can't find the inventory
		return 0
	}

	const lastPeriodIndex = otherCurrentAssetsRow.data.length - 1

	//compute inventory
	const inventoryRows = otherCurrentAssetsRow.children.filter((row) =>
		row.name.includes("Inventory"),
	)
	let inventorySum = 0
	for (const inventoryRow of inventoryRows) {
		const inventoryCell = inventoryRow.data[lastPeriodIndex]
		const lastPeriodInventoryValue = inventoryCell.value
		inventorySum += lastPeriodInventoryValue
	}

	let cogsDuringPeriod = 0
	for (let i = 0; i < table.columns.length; i++) {
		const cogsCell = table.cogsRow.data[i]
		cogsDuringPeriod += cogsCell.value
	}
	const startDate = new Date(table.columns[lastPeriodIndex].startDate)
	const endDate = new Date(table.columns[lastPeriodIndex].endDate)
	const daysInPeriod =
		(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

	return (inventorySum / cogsDuringPeriod) * daysInPeriod
}
