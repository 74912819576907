import { twMerge } from "tailwind-merge"

type TableBodyProps = {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

export const TableBody:React.FC<TableBodyProps> = ({
  className,
  children
}) => {
  return (
    <tbody
      className={twMerge(
        className,
        `border-b border-gray-200`
      )}
    >
      {children}
    </tbody>
  )
}