import { Progress } from "@/components/ui/progress"
import { useEffect, useState } from "react"

type FakeProgressBarProps = {
  estimatedTime: number,
  className?: string
}

export const FakeProgressBar:React.FC<FakeProgressBarProps> = ({
  estimatedTime,
  className
}) => {
  const [progress, setProgress] = useState(0)

  const granularity = 10

  useEffect(() => {
    const interval = setInterval(() => {
      if(progress < 100) {
        setProgress(progress + (1/granularity)*(100/estimatedTime))
      }
    }, 1000/granularity)
    return () => clearInterval(interval)
  },[progress, estimatedTime])

  return (
    <Progress
      className={className}
      value={progress}
      max={100}
    />
  )
}