import trpc from '../../../trpc'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Organization } from '../../../types'
import { useState } from 'react'
import { Spin } from 'antd'
import { Loader2Icon } from 'lucide-react'

const formSchema = z.object({
	name: z.string().min(1)
})

type FormSchemaType = z.infer<typeof formSchema>

type CreateOrganizationViewProps = {
  onCreated: (organization: Organization) => void

  /**
   * If true, the mutation will not be executed.
   */
  dryRun?: boolean
}

export const CreateOrganizationView:React.FC<CreateOrganizationViewProps> = ({
  onCreated,
  dryRun = false,
}) => {

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

	const createCompany = trpc.createOrganization.useMutation({
    onError: (error) => {
      setSubmitLoading(false)
      console.error(error)
      setError("Something's gone wrong.")
    },
  })

	const form = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			name: ""
		}
	})

	const onSubmit = async (data: FormSchemaType) => {
    setSubmitLoading(true)
    try {
      let company: Organization
      if(!dryRun) {
        company = await createCompany.mutateAsync(data)
      }
      else{
        company = {
          id: 0,
          name: data.name,
          ownerID: 0,
          lastUsed: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        }
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
      onCreated(company)
    }
    catch(e) {
      setSubmitLoading(false)
      console.error(e)
    }
	}


	return (
		<Spin
      spinning={submitLoading}
      tip="Creating your company..."
      indicator={
        <Loader2Icon className="animate-spin text-gray-500" />
      }
      className="text-gray-500"
    >
			<Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="name"
            render={({ field }: { field: any }) => (
              <FormItem>
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input placeholder="Example Industries" {...field} />
                </FormControl>
                <FormDescription>
                  The display name of your company.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Create</Button>
        </form>
      </Form>	
      {
        error !== null &&
        <div className="text-red-500">
          {error}
        </div>
      }
		</Spin>
	)
}