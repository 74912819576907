import { Frequency } from "../../../../../backend/src/BusinessLogic"
import { Loader2Icon } from "lucide-react"

import {
	CartesianGrid,
	LabelList,
	Line,
	LineChart as RechartsLineChart,
	ReferenceLine,
	XAxis,
	YAxis,
} from "recharts"

import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart"
import { moveDateBack } from "utils"
import { useWorkingCapitalTable } from "../../../Components/WorkingCapitalTable/useWorkingCapitalTable"

type CurrentRatioLineChartProps = {
	options: {
		startDate: Date
		endDate: Date
		frequency: Frequency
	}
}

/**
 * Renders a line chart of the current ratio for the organization.
 *
 * This component will query the necessary data.
 *
 * @param options - The options for the chart.
 * @returns
 */
export const CurrentRatioLineChart: React.FC<CurrentRatioLineChartProps> = ({
	options,
}) => {
	//move the start date back by one time period to use change in current ratio
	const startDateToUse = moveDateBack(options.startDate, options.frequency)

	const { table, isLoading, error } = useWorkingCapitalTable({
		tableOptions: {
			startDate: startDateToUse,
			endDate: options.endDate,
			frequency: options.frequency,
		},
	})

	if (error) {
		return (
			<div className="text-red-500 flex flex-col items-center justify-center text-center">
				Error: {error}
			</div>
		)
	}

	if (isLoading || !table) {
		return (
			<div className="flex flex-col items-center justify-center">
				<Loader2Icon className="animate-spin text-gray-500" />
			</div>
		)
	}

	const currAssets = table.currentAssetsRow ?? []
	const currLiabilities = table.currentLiabilitiesRow ?? []
	const times = table.columns ?? []

	let chartData = []
	let maxCurrentRatio = -Infinity
	let minCurrentRatio = Infinity
	const length = times.length
	let lastCurrentRatio: number | null = null

	for (let i = 0; i < length; i++) {
		let currentRatioRaw = 0
		if (currAssets.data[i].value !== 0 && currLiabilities.data[i].value !== 0) {
			currentRatioRaw = currAssets.data[i].value / currLiabilities.data[i].value
		}
		if (lastCurrentRatio === null) {
			lastCurrentRatio = currentRatioRaw
			continue
		}
		if (currentRatioRaw > maxCurrentRatio) {
			maxCurrentRatio = currentRatioRaw
		}
		if (currentRatioRaw < minCurrentRatio) {
			minCurrentRatio = currentRatioRaw
		}
		const percentChange =
			((currentRatioRaw - lastCurrentRatio) / lastCurrentRatio) * 100
		lastCurrentRatio = currentRatioRaw

		let label = `${currentRatioRaw.toFixed(1)} `

		if (percentChange > 0) {
			label += `+${percentChange.toFixed(1)}%`
		} else {
			label += `${percentChange.toFixed(1)}%`
		}
		chartData.push({
			time: times[i].name,
			startDate: times[i].name.slice(14),
			currentRatioRaw: currentRatioRaw,
			label,
			mobile: 80,
		})
	}

	const yAxisMax = Math.max(2.1, maxCurrentRatio)
	const yAxisMin = Math.min(0, minCurrentRatio)

	const chartConfig = {
		currRatio: {
			label: "Current Ratio",
			color: "#c084fc",
		},
	} satisfies ChartConfig

	return (
		<div className="w-full h-full p-4 border border-gray-200 rounded-lg pl-0 flex flex-row justify-center items-center">
			<div className="w-full h-full">
				<ChartContainer config={chartConfig}>
					<RechartsLineChart
						accessibilityLayer
						data={chartData}
						margin={{
							top: 20,
							left: 12,
							right: 12,
						}}
					>
						<CartesianGrid vertical={false} />
						<XAxis
							dataKey="time"
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							tickFormatter={(value) => value.slice(0, 3)}
						/>
						<YAxis
							label={{
								value: "Current Ratio Value",
								angle: -90,
								position: "insideLeft",
								textAnchor: "middle",
							}}
							tickMargin={8}
							domain={[yAxisMin, yAxisMax]}
						/>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent indicator="line" />}
						/>
						<Line
							dataKey="currentRatioRaw"
							name="Current Ratio"
							type="natural"
							stroke="#c084fc"
							strokeWidth={2}
							dot={{}}
							activeDot={{
								r: 6,
							}}
						>
							<LabelList
								position="top"
								offset={12}
								className="fill-foreground"
								fontSize={12}
								dataKey="label"
							/>
						</Line>
						<style>
							{`
								g.redReferenceLine>line.recharts-reference-line-line {
									stroke: red;
								}
							`}
						</style>
						<ReferenceLine
							y={1.2}
							className="redReferenceLine"
							stroke="red"
							strokeDasharray="3 3"
						/>
						<ReferenceLine
							y={2}
							className="redReferenceLine"
							stroke="red"
							strokeDasharray="3 3"
						/>
					</RechartsLineChart>
				</ChartContainer>
			</div>
		</div>
	)
}
