import { useCallback, useContext } from "react"
import { OrganizationContext, OrganizationContextType } from "../../Contexts"
import { Stage } from "./Stage"
import { CreateOrganizationView } from "./OrganizationStage"
import { QuickbooksIntegrationView } from "./IntegrationStage"
import { Organization } from "../../types"

const getStage = (organizationContext: OrganizationContextType) => {
	if (organizationContext.organization === null) {
		return 0
	}
	if (organizationContext.integrationStatus.length === 0) {
		return 1
	}
	return 2
}

export const OnboardPageContent = () => {
	const [organizationContext, setOrganizationContext] =
		useContext(OrganizationContext)

	const onOrganizationCreated = useCallback(
		(organization: Organization) => {
			setOrganizationContext({
				organization,
				integrationStatus: [],
			})
		},
		[setOrganizationContext],
	)

	//when an integration is created, the user is routed back here
	//and the query(in OrganizationContextProvider) should automatically refetch

	//determine stage
	const stage = getStage(organizationContext)

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<div
				className={`
					flex flex-col items-center justify-center
					p-4
				`}
			>
				<span className="text-4xl font-bold text-gray-900">Setup CFO4ALL</span>
			</div>
			<div
				className={`
					flex flex-col items-center justify-center
					border-2 border-gray-200 rounded-lg
					w-[80%]
				`}
			>
				<Stage
					stageNumber={1}
					stageName="Create your company"
					completed={stage > 0}
					disabled={false}
					isFirst
				>
					<CreateOrganizationView onCreated={onOrganizationCreated} />
				</Stage>
				<Stage
					stageNumber={2}
					stageName="Connect to Quickbooks"
					completed={stage > 1}
					disabled={stage < 1}
				>
					<QuickbooksIntegrationView />
				</Stage>
			</div>
		</div>
	)
}

export default OnboardPageContent
