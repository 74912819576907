import { Frequency } from "../../../backend/src/BusinessLogic"
import { DatePickerDemo } from "./DatePicker"
import { SimpleSelect } from "./SimpleSelect"

const frequencyOptions = [
	"Daily",
	"Weekly",
	"Monthly",
	"Quarterly",
	"Yearly",
] as const

const convertToUppercase = (frequency: Frequency): string => {
	return frequency.charAt(0).toUpperCase() + frequency.slice(1)
}

type StartEndFrequencySelectorProps = {
	startDate: Date
	endDate: Date
	frequency: Frequency
	onStartDateChange: (date: Date) => void
	onEndDateChange: (date: Date) => void
	onFrequencyChange: (frequency: Frequency) => void
}

export const StartEndFrequencySelector: React.FC<
	StartEndFrequencySelectorProps
> = ({
	startDate,
	endDate,
	frequency,
	onStartDateChange,
	onEndDateChange,
	onFrequencyChange,
}) => {
	return (
		<div className="flex flex-row items-center justify-center space-x-2 flex-wrap">
			<div className="flex flex-col items-center justify-center space-y-2">
				<label htmlFor="CashInflowStartDate">Start Date</label>
				<DatePickerDemo
					id="CashInflowStartDate"
					onSelect={onStartDateChange}
					defaultDate={startDate}
				/>
			</div>
			<div className="flex flex-col items-center justify-center space-y-2">
				<label htmlFor="CashInflowEndDate">End Date</label>
				<DatePickerDemo
					id="CashInflowEndDate"
					onSelect={onEndDateChange}
					defaultDate={endDate}
				/>
			</div>
			<div className="flex flex-col items-center justify-center space-y-2">
				<label htmlFor="frequency">Frequency</label>
				<SimpleSelect
					id="frequency"
					onSelect={(value) => {
						onFrequencyChange(value.toLowerCase() as Frequency)
					}}
					options={[...frequencyOptions]}
					label="Frequency"
					defaultValue={convertToUppercase(frequency)}
				/>
			</div>
		</div>
	)
}
