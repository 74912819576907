import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

type MarkdownRendererProps = {
	markdown: string
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
	markdown,
}) => {
	return (
		<div className="flex flex-col items-start justify-start space-y-4 markdown">
			<ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
		</div>
	)
}
