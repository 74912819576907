import { twMerge } from "tailwind-merge"

type StickyCellOverlayProps = {
  shadow?: boolean
  stickyOverlayTags?: string
}

const StickyCellOverlay:React.FC<StickyCellOverlayProps> = ({
  shadow,
  stickyOverlayTags
}) => {
  return (
    <div
      className={twMerge(`
        z-[-1]
        border-r border-l border-gray-200
        absolute
        top-0
        left-0
        w-full
        h-full
        bg-white
        ${shadow ? "shadow-[0px_0_10px_0px_rgba(0,0,0,0.1)] z-10" : ""}
      `, stickyOverlayTags)}
    />
  )
}

type TableCellProps = {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
  sticky?: boolean
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  shadow?: boolean
  stickyOverlayTags?: string
  onClick? : (arg: React.MouseEvent<HTMLTableCellElement>) => void
}

export const TableCell:React.FC<TableCellProps> = ({
  className,
  children,
  sticky,
  top,
  bottom,
  left,
  right,
  shadow,
  stickyOverlayTags,
  onClick
}) => {
  return (
    <td
      className={twMerge(
        `border-r border-gray-200 p-2 relative hover:bg-gray-100
          ${sticky ? "sticky bg-white border-width-0 z-[5]" : "z-0"}
          ${top ? "top-0" : ""}
          ${bottom ? "bottom-0" : ""}
          ${left ? "left-0" : ""}
          ${right ? "right-0" : ""}
          `,
        className
      )}
      onClick={onClick}
    >
      <div
      >
        {children}
      </div>
      {sticky ?
        <StickyCellOverlay
          stickyOverlayTags={stickyOverlayTags}
        />
        :
        null
      }
    </td>
  )
}