import { twMerge } from "tailwind-merge"
import { Table } from "../Table"
import { Loader2Icon } from "lucide-react"

type DataTableProps = {
	className?: string
	children?: React.ReactNode | React.ReactNode[]
	onRowOpen?: (rowName: string) => void
	onRowClose?: (rowName: string) => void
	isLoading?: boolean
	error?: string
}

export const DataTable: React.FC<DataTableProps> = ({
	className,
	children,
	onRowOpen,
	onRowClose,
	isLoading = false,
	error,
}) => {
	const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		console.log("scroll")
		console.log(e.target)
	}

	if (error) {
		return <p className="text-red-500">Error: {error}</p>
	}
	if (isLoading) {
		return (
			<div className="flex flex-col items-center justify-center h-fit">
				<Loader2Icon className="animate-spin text-gray-500" />
			</div>
		)
	}

	return (
		<div
			className={twMerge(
				`flex flex-col items-center justify-center h-fit
        border-2 border-gray-200 rounded-lg p-4
        max-h-[60%]
        overflow-y-clip
        `,
				className,
			)}
		>
			<Table onScroll={onScroll} className="border-0">
				{children}
			</Table>
		</div>
	)
}
