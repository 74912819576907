import { Frequency } from "../../../backend/src/BusinessLogic"

export const moveDateBack = (
	date: Date,
	frequency: Frequency,
	periods: number = 1,
): Date => {
	const newDate = new Date(date)
	switch (frequency) {
		case "daily":
			newDate.setDate(newDate.getDate() - periods)
			break
		case "weekly":
			newDate.setDate(newDate.getDate() - periods * 7)
			break
		case "monthly":
			newDate.setMonth(newDate.getMonth() - periods)
			break
		case "quarterly":
			newDate.setMonth(newDate.getMonth() - periods * 3)
			break
		case "yearly":
			newDate.setFullYear(newDate.getFullYear() - periods)
			break
		default:
			throw new Error("Unknown frequency")
	}
	return newDate
}
