import React from "react"
import { twMerge } from "tailwind-merge"

type TableRowProps = {
  className?: string
  children?: React.ReactNode | React.ReactNode[],
  expandableChildren?: React.ReactNode | React.ReactNode[],
  expanded?: boolean
}

export const TableRow:React.FC<TableRowProps> = ({
  className,
  children,
  expandableChildren,
  expanded
}) => {
  return (
    <>
      <tr
        className={twMerge(
          `border-b border-gray-200`,
          className
        )}
      >
        {children}
      </tr>
      {
        expanded &&
        expandableChildren
      }
    </>
  )
}