import { SignUp } from "@clerk/clerk-react"

const SignUpPage:React.FC = () => {
  return(
    <div className="flex flex-col items-center justify-center h-screen">
      <SignUp
        path="/sign-up"
        signInUrl="/sign-in"
        forceRedirectUrl="/user-creation"
      />
    </div>
  )
}

export default SignUpPage