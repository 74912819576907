import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"

export type AccordionDataType = {
	AccordionTitle: string
	AccordionSummary: string
	subsections: {
		title: string
		contents: string[]
	}[]
}

interface AccordionComponentProps {
	data: AccordionDataType[]
}

export function AccordionComponent({ data }: AccordionComponentProps) {
	return (
		<Accordion type="single" collapsible className="w-full">
			{data.map((accordionData, index) => (
				<AccordionItem key={index} value={accordionData.AccordionTitle}>
					<AccordionTrigger>{accordionData.AccordionTitle}</AccordionTrigger>
					<AccordionContent>
						<p>{accordionData.AccordionSummary}</p>
						{accordionData.subsections.map((subsection, index) => (
							<div key={index} className="ml-4">
								<h3 className="font-semibold">{subsection.title}</h3>
								<ul>
									{subsection.contents.map((content, index) => (
										<li key={index}>{content}</li>
									))}
								</ul>
							</div>
						))}
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	)
}

//
// export function AccordionComponent({data}: AccordionComponentProps) {
//     return (
//         <Accordion type="single" collapsible className="w-full">
//             <AccordionItem value="item-1">
//                 <AccordionTrigger>Is it accessible?</AccordionTrigger>
//                 <AccordionContent>
//                     Yes. It adheres to the WAI-ARIA design pattern.
//                 </AccordionContent>
//             </AccordionItem>
//             <AccordionItem value="item-2">
//                 <AccordionTrigger>Is it styled?</AccordionTrigger>
//                 <AccordionContent>
//                     Yes. It comes with default styles that matches the other
//                     components&apos; aesthetic.
//                 </AccordionContent>
//             </AccordionItem>
//             <AccordionItem value="item-3">
//                 <AccordionTrigger>Is it animated?</AccordionTrigger>
//                 <AccordionContent>
//                     Yes. It&apos;s animated by default, but you can disable it if you
//                     prefer.
//                 </AccordionContent>
//             </AccordionItem>
//         </Accordion>
//     )
// }
