import { calculateDIOForAllTable } from "./calculateDIO"
import { calculateDPOForAllTable } from "./calculateDPO"
import { calculateDSOForAllTable } from "./calculateDSO"
import { WorkingCapitalTable } from "./types"

type CCCDatapoint = {
	CCC: number
}

export const calculateCCC = (
	DIO: { DIO: number }[],
	DSO: { DSO: number }[],
	DPO: { DPO: number }[],
): CCCDatapoint[] => {
	const output: CCCDatapoint[] = []
	for (let i = 0; i < DIO.length; i++) {
		const DIO_value = DIO[i].DIO
		const DSO_value = DSO[i].DSO
		const DPO_value = DPO[i].DPO
		const CCC_value = DIO_value + DSO_value - DPO_value
		output.push({
			CCC: CCC_value,
		})
	}
	return output
}

export const calculateCCCForAllTable = (table: WorkingCapitalTable): number => {
	const DIO = calculateDIOForAllTable(table)
	const DSO = calculateDSOForAllTable(table)
	const DPO = calculateDPOForAllTable(table)
	return DIO + DSO - DPO
}
