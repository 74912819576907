import trpc from '../../../trpc'
import QuickbooksLogo from "../../../Assets/QuickbooksLogo.svg"
import { Button } from "@/components/ui/button"

export const QuickbooksIntegrationView = () => {
  const quickbooksAuthURI = trpc.getQuickbooksAuthURI.useQuery({})

  return (
    <div
      className="flex flex-row justify-center space-x-4"
    >
      <img
        src={QuickbooksLogo}
        alt="Quickbooks logo"
      />
      <Button
        asChild
      >
        <a href={quickbooksAuthURI.data}>
          Go to Quickbooks
        </a>
      </Button>
    </div>
  )
}