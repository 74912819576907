
export const formatPercentage = (
  value: number,
): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const digitFormatted = formatter.format(value)
  return digitFormatted
}