import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { cn } from "@/lib/utils"

type SimpleSelectProps = {
  id?: string
  className?: string
  label?: string
  defaultValue?: string
  placeholder?: string
  options: string[]
  onSelect?: (value: string) => void
  onOpenChange?: (open: boolean) => void
}

export const SimpleSelect:React.FC<SimpleSelectProps> = ({
  id,
  className,
  label,
  options,
  onSelect,
  onOpenChange,
  defaultValue,
  placeholder,
}) => {
  return (
    <Select
      onValueChange={onSelect}
      onOpenChange={onOpenChange}
      defaultValue={defaultValue}
    >
      <SelectTrigger
        id={id}
        className={cn("w-[180px]", className)}
      >
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {label &&
            <SelectLabel>{label}</SelectLabel>
          }
          {
            options.map((option, index) => (
              <SelectItem
                key={index}
                value={option}
              >
                {option}
              </SelectItem>
            ))
          }
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}