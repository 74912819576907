import { formatCurrency } from "../../../utils"
import { BasicTableOptions, TransactionAttachment } from "../types"

type TransactionViewProps = {
	transaction: TransactionAttachment
	options: BasicTableOptions
}

export const TransactionView: React.FC<TransactionViewProps> = ({
	transaction,
	options,
}) => {
	const date = new Date(transaction.date)
	const dateString = date.toLocaleDateString()

	const formattedValue = formatCurrency(transaction.value, options.currency)
	return (
		<div className="flex flex-col items-start space-x-1">
			<span className="text-sm font-medium">Transaction</span>
			<span className="text-sm">{transaction.name}</span>
			<span className="text-sm">{dateString}</span>
			<div className="border-t-[1px] border-gray-300 flex flex-row items-start space-x-1 mt-2">
				<span>{formattedValue}</span>
			</div>
		</div>
	)
}
