import { WorkingCapitalTable } from "./types"

type DSODatapoint = {
	DSO: number
}

/**
 * Calculates DSO for each period except the first 6
 * The first 6 are not included because they are used
 * for filling in missing data
 *
 * @param table
 * @returns
 */
export const calculateDSO = (table: WorkingCapitalTable): DSODatapoint[] => {
	const output: DSODatapoint[] = []
	for (let i = 6; i < table.columns.length; i++) {
		const column = table.columns[i]

		const AREndingCell = table.AREndBalanceRow.data[i]
		const ARStartCell = table.AREndBalanceRow.data[i - 1]
		const ARAverageLast2Periods = (ARStartCell.value + AREndingCell.value) / 2

		const creditSalesCell = table.creditSalesRow.data[i]
		let creditSalesValue = creditSalesCell.value
		if (creditSalesValue <= 0) {
			//can't divide by 0
			//fix the gap with the average of the last 6 periods
			creditSalesValue = 0
			for (let j = i - 6; j < i; j++) {
				creditSalesValue += table.creditSalesRow.data[j].value
			}
			creditSalesValue /= 6
			if (creditSalesValue === 0) {
				creditSalesValue = 1
			}
		}

		const startDate = new Date(column.startDate)
		const endDate = new Date(column.endDate) //end date is exclusive
		const daysInPeriod =
			(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

		const DSO = (ARAverageLast2Periods / creditSalesValue) * daysInPeriod

		output.push({
			DSO: DSO,
		})
	}
	return output
}

export const calculateDSOForAllTable = (table: WorkingCapitalTable): number => {
	const lastPeriodIndex = table.columns.length - 1
	const AREndingCell = table.AREndBalanceRow.data[lastPeriodIndex]

	let cogsDuringPeriod = 0
	for (let i = 0; i < table.columns.length; i++) {
		const cogsCell = table.cogsRow.data[i]
		cogsDuringPeriod += cogsCell.value
	}

	const startDate = new Date(table.columns[lastPeriodIndex].startDate)
	const endDate = new Date(table.columns[lastPeriodIndex].endDate)
	const daysInPeriod =
		(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

	return (AREndingCell.value / cogsDuringPeriod) * daysInPeriod
}
