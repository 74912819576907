import { Check } from "lucide-react"

type StageProps = {
  stageNumber: number
  stageName: string
  completed: boolean
  disabled?: boolean
  children?: React.ReactNode | React.ReactNode[]
  isFirst?: boolean
}

export const Stage:React.FC<StageProps> = ({
  stageNumber,
  stageName,
  completed,
  disabled,
  children,
  isFirst
}) => {

  return (
    <div
      className={`
        relative
        w-full h-fit
        p-4
        bg-gray-100
        ${!isFirst&& "border-t-2 border-gray-200"}
        flex flex-col items-center justify-center
      `}
    >
      <div 
        className={`
          w-full h-fit
          flex flex-row items-center justify-between
          ${!completed && !disabled && "pb-4"}
        `}
      >
        <div
          className={`
            ${completed ? 'bg-green-500' : disabled ? 'bg-gray-200' : 'bg-gray-500'}
            w-12 h-12 rounded-full flex items-center justify-center
            text-white text-2xl font-bold
          `}
        >
          {stageNumber}
        </div>
        <div
          className="text-xl font-bold"
        >
          {stageName}
        </div>
        <div
          className=""
        >
          {completed &&
            <Check /> 
          }
        </div>
      </div>
      {!completed && !disabled &&
        <div
          className="pt-4 border-t-2 border-gray-200 w-full flex justify-center items-center"
        >
          <div
            className="w-fit"
          >
            {children}
          </div>
        </div>
      }
      {disabled && 
        <div
        className="absolute top-0 left-0 w-full h-full bg-gray-200 opacity-50" />
      }
    </div>
	)
}