import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import trpc from "../../trpc"
import { OrganizationContext } from "../../Contexts"
import { Loader2Icon } from "lucide-react"
import _ from "lodash"

type Timeout = ReturnType<typeof setTimeout>

export const QuickbooksCallback: React.FC = () => {
	const [error, setError] = useState<string | null>(null)

	const [organizationContext, setOrganizationContext] =
		useContext(OrganizationContext)

	//get query params
	const [searchParams] = useSearchParams()

	let realmId: string | null = null
	let state: string | null = null
	let code: string | null = null

	searchParams.forEach((value, key) => {
		if (key === "realmId") {
			realmId = value
		}
		if (key === "state") {
			state = value
		}
		if (key === "code") {
			code = value
		}
	})

	const navigate = useNavigate()

	const [mutationFinished, setMutationFinished] = useState(false)
	const registerQuickbooks = trpc.createQuickbooksIntegration.useMutation({
		onSuccess: (data) => {
			//force a refresh of the organization context
			setOrganizationContext(_.cloneDeep(organizationContext))
			setMutationFinished(true)
		},
		onError: (error) => {
			setError("Something's wrong. Please try again.")
			console.error(error)
		},
	})

	const [lastTimeout, setLastTimeout] = useState<Timeout | null>(null)

	//sends the request to the backend
	useEffect(() => {
		if (organizationContext.organization === null) {
			return
		} else if (
			registerQuickbooks.isError ||
			registerQuickbooks.isPending ||
			registerQuickbooks.isSuccess
		) {
			return
		} else if (realmId !== null && state !== null && code !== null) {
			registerQuickbooks.mutate({
				organizationId: organizationContext.organization.id,
				realmId,
				state,
				code,
			})
		}
	}, [
		organizationContext.organization,
		realmId,
		state,
		code,
		registerQuickbooks,
	])

	//checks that the integration has come through to context
	useEffect(() => {
		if (!mutationFinished) {
			return
		}
		if (
			organizationContext.organization !== null &&
			organizationContext.integrationStatus.length > 0
		) {
			if (lastTimeout) {
				clearTimeout(lastTimeout)
			}
			//ready to move on
			navigate("/onboarding")
			return
		}
		//cause a refetch in 1000ms
		setLastTimeout(
			setTimeout(() => {
				setOrganizationContext(_.cloneDeep(organizationContext))
			}, 1000),
		)
	}, [
		organizationContext,
		mutationFinished,
		navigate,
		setOrganizationContext,
		lastTimeout,
	])

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			{error !== null ? (
				<span className="text-red-500">{error}</span>
			) : (
				<>
					<span className="text-4xl font-bold text-gray-900">
						Thanks for connecting Quickbooks!
					</span>
					<span>Setting up your account</span>
					<Loader2Icon className="animate-spin text-gray-500" />
				</>
			)}
		</div>
	)
}
