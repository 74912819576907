import { Suspense } from "react"
import { InvoiceView } from "./InvoiceView"
import {
	Assertion,
	BasicTableOptions,
	Column,
	DataPoint,
	RowData,
	TransactionAttachment,
} from "../types"

import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog"
import { FakeProgressBar } from "../../FakeProgressBar"
import { BillView } from "./BillView"
import { AssertionView } from "./AssertionView"
import {
	BillAttachment,
	InvoiceAttachment,
	QuickbooksCashAttachment,
} from "../../../../../backend/src/Quickbooks"
import { QuickbooksCashAttachmentView } from "./QuickbooksCashAttachmentView"
import { TransactionView } from "./TransactionView"
import { NetChangeAttachment } from "../../../../../backend/src/BusinessLogic/Attachments"
import { formatCurrency } from "../../../utils"

type AttachmentViewProps = {
	attachment:
		| BillAttachment
		| InvoiceAttachment
		| Assertion
		| QuickbooksCashAttachment
		| TransactionAttachment
	rowName: string
	columnName: string
	datapoint: DataPoint
	options: BasicTableOptions
}

const AttachmentView: React.FC<AttachmentViewProps> = ({
	attachment,
	rowName,
	datapoint,
	options,
}) => {
	if (attachment.type === "QuickbooksBill") {
		return <BillView billAttachment={attachment} />
	}
	if (attachment.type === "QuickbooksInvoice") {
		return <InvoiceView invoiceAttachment={attachment} />
	}
	if (attachment.type === "Assertion") {
		return <AssertionView assertion={attachment} rowName={rowName} />
	}
	if (attachment.type === "QuickbooksCash") {
		return (
			<QuickbooksCashAttachmentView
				cashAttachment={attachment}
				datapoint={datapoint}
			/>
		)
	}
	if (attachment.type === "Transaction") {
		return <TransactionView transaction={attachment} options={options} />
	}
	return <span>Unknown attachment type</span>
}

type CellInfoModalProps = {
	datapoint: DataPoint
	row: RowData
	column: Column
	options: BasicTableOptions
}

const CellInfoModalContent: React.FC<CellInfoModalProps> = ({
	datapoint,
	row,
	column,
	options,
}) => {
	const columnName = column.name
	const rowName = row.alias || row.name

	//check for a net change attachment
	const netChangeAttachment = datapoint.attachment?.find(
		(attachment) => attachment && attachment.type === "NetChange",
	)

	const total = netChangeAttachment
		? (netChangeAttachment as NetChangeAttachment).value
		: datapoint.value

	const formattedTotal = formatCurrency(total, options.currency)

	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>Entires in {rowName}</DialogTitle>
				<DialogDescription>During {columnName}</DialogDescription>
				<div className="flex flex-row items-center space-x-1 font-bold">
					<span>Total:</span>
					<span>{formattedTotal}</span>
				</div>
			</DialogHeader>
			<div
				className={`
          flex flex-col justify-start items-stretch max-h-[60vh]
        `}
			>
				<Suspense fallback={<FakeProgressBar estimatedTime={5} />}>
					<div className="flex flex-col items-stretch overflow-y-scroll h-full space-y-2">
						{datapoint.attachment !== undefined &&
							datapoint.attachment.map((attachment, index) => {
								if (!attachment) {
									return null
								}
								if (attachment.type === "NetChange") {
									return null
								}
								return (
									<div
										key={index}
										className="flex flex-row items-center justify-start hover:bg-gray-100"
									>
										<AttachmentView
											attachment={attachment}
											rowName={rowName}
											columnName={columnName}
											datapoint={datapoint}
											options={options}
										/>
									</div>
								)
							})}
					</div>
				</Suspense>
			</div>
		</DialogContent>
	)
}

export {
	Dialog as CellInfoModal,
	DialogTrigger as CellInfoModalTrigger,
	CellInfoModalContent,
}
