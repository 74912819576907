import { useCallback, useEffect, useRef, useState } from "react"
import { MoreInfoTrigger } from "./MoreInfoTrigger"
import { ChevronsDownUp } from "lucide-react"

type MoreInfoOption = {
	trigger: React.ReactNode
	content: React.ReactNode
}

type MoreInfoSelectorProps = {
	options: MoreInfoOption[]
}

export const MoreInfoSelector: React.FC<MoreInfoSelectorProps> = ({
	options,
}) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(0)
	const [open, setOpen] = useState(false)

	const selectedOption = selectedIndex !== null ? options[selectedIndex] : null

	//for animation
	const [height, setHeight] = useState(0)
	const contentRef = useRef<HTMLDivElement>(null)

	const resizeContent = useCallback(() => {
		if (!contentRef.current) {
			return
		}
		if (!open) {
			setHeight(0)
			return
		}
		setHeight(contentRef.current.scrollHeight)
	}, [open, contentRef, setHeight])

	useEffect(() => {
		if (!contentRef.current) return
		const observer = new ResizeObserver(resizeContent)
		observer.observe(contentRef.current)
		return () => {
			observer.disconnect()
		}
	}, [contentRef, resizeContent])

	useEffect(resizeContent, [open, contentRef, selectedIndex, resizeContent])

	return (
		<div className="flex flex-col items-center justify-center space-y-4 w-full">
			<div className="flex flex-row items-center justify-center flex-wrap">
				{options.map((option, index) => (
					<MoreInfoTrigger
						key={index}
						onClick={() => {
							if (selectedIndex === index) {
								setOpen(!open)
								return
							}
							setSelectedIndex(index)
							setOpen(true)
						}}
						selected={selectedIndex === index && open}
					>
						{option.trigger}
					</MoreInfoTrigger>
				))}
			</div>
			<div
				className={`flex flex-col space-y-2 w-full transition-all duration-500 ease-in-out transform ${
					open ? "opacity-100" : "opacity-0"
				} overflow-hidden `}
				style={{
					height: `${height}px`,
				}}
			>
				<div ref={contentRef}>
					{selectedOption && (
						<>
							<div>{selectedOption.content}</div>
							<div className="flex flex-row w-full items-center justify-center ">
								<div
									className="flex flex-row items-center justify-center space-x-1 text-gray-500 cursor-pointer hover:text-black"
									onClick={() => {
										setOpen(false)
									}}
								>
									<ChevronsDownUp className="h-4 w-4" />
									<span>Hide</span>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
