import {
	BasicTableOptions,
	TableState,
	Column,
	DataPoint,
	RowData,
} from "./types"
import {
	CellInfoModal,
	CellInfoModalTrigger,
	CellInfoModalContent,
} from "./CellInfoModal"
import { formatCurrency, formatPercentage } from "../../utils"
import { TableCell } from "../Table"
import { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"
import { HelpIcon } from "../HelpIcon"
import { match } from "node-match-path"
import { usePostHog } from "posthog-js/react"

const getFormattedValue = (
	datapoint: DataPoint,
	options: BasicTableOptions,
): string => {
	if (datapoint.datapointType === "Currency") {
		return formatCurrency(datapoint.value, options.currency)
	}
	if (datapoint.datapointType === "Percentage") {
		return formatPercentage(datapoint.value)
	}
	throw new Error("Unknown datapoint type")
}

const getCashWarningTags = (
	datapoint: DataPoint,
	row: RowData,
	options: BasicTableOptions,
): string | undefined => {
	const number = datapoint.value
	const path = row.path
	if (!options.warnings) {
		return undefined
	}
	for (const warning of options.warnings) {
		const pathMatch = match(warning.path, path)
		if (!pathMatch.matches) {
			continue
		}
		if (
			(warning.triggerBelow && number < warning.threshold) ||
			(!warning.triggerBelow && number > warning.threshold)
		) {
			return warning.colorTag
		}
	}
	return undefined
}

const isAttachmentMeaningful = (dataPoint: DataPoint): boolean => {
	if (dataPoint.attachment === undefined) {
		return false
	}
	if (dataPoint.attachment.length === 0) {
		return false
	}
	for (const attachment of dataPoint.attachment) {
		if (attachment?.type !== "NetChange") {
			return true
		}
	}
	return false
}

type EditableTableCellProps = {
	dataPoint: DataPoint
	tableState: TableState
	onEdit?: (newValue: number) => void
}

const EditableTableCell: React.FC<EditableTableCellProps> = ({
	dataPoint,
	tableState,
	onEdit,
}) => {
	const [currentValue, setCurrentValue] = useState<string>("" + dataPoint.value)

	const onError = (error: string): void => {
		alert(error)
	}

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				const newValue = parseFloat(currentValue)
				if (isNaN(newValue)) {
					onError("Please enter a valid number")
					return
				}
				onEdit && onEdit(newValue)
			}}
		>
			<input
				title="Cell Edit"
				className="w-full p-2"
				type="float"
				value={currentValue.toString()}
				onChange={(e) => {
					setCurrentValue(e.target.value)
				}}
				autoFocus
			/>
		</form>
	)
}

type CashflowTableDataCellProps = {
	tableState: TableState
	dataPoint: DataPoint
	row: RowData
	column: Column
	showAdditionalInfo?: boolean
	onEdit?: (newValue: number) => void
	cellClassName?: string
}

/**
 *
 * Renders a data cell for the table
 * The cell can be editable
 * The cell can display a currency or a percentage
 * specified by the datapoint
 *
 * @param dataPoint - The data point to display
 * @param showAdditionalInfo - Whether to display a help circle with additional information if you click on it
 * @returns
 */
export const DataCell: React.FC<CashflowTableDataCellProps> = ({
	tableState,
	dataPoint,
	row,
	column,
	showAdditionalInfo,
	onEdit,
	cellClassName,
}) => {
	const [, /*cellValue*/ setCellValue] = useState<number>(dataPoint.value)
	useEffect(() => {
		setCellValue(dataPoint.value)
	}, [dataPoint.value])

	const [editMode, setEditMode] = useState(false)
	const [currentlyEditingCell, setCurrentlyEditingCell] =
		tableState.currentlyEditingCell

	useEffect(() => {
		if (editMode && !currentlyEditingCell) {
			setEditMode(false)
		}
	}, [currentlyEditingCell, editMode])

	const cashWarningTags = getCashWarningTags(dataPoint, row, tableState.options)

	const meaningfulAttachment = isAttachmentMeaningful(dataPoint)

	const editable = dataPoint.datapointType === "Currency"

	const formattedValue = getFormattedValue(dataPoint, tableState.options)

	const posthog = usePostHog()

	return (
		<TableCell
			className={twMerge(cellClassName, editMode ? "p-0" : "")}
			onClick={(e) => {
				if (editMode) {
					return
				}
				if (!currentlyEditingCell) {
					if (!editable) {
						return
					}
					setEditMode(true)
					setCurrentlyEditingCell(true)
				} else {
					setCurrentlyEditingCell(false)
				}
			}}
		>
			{editMode ? (
				<EditableTableCell
					dataPoint={dataPoint}
					tableState={tableState}
					onEdit={(newValue) => {
						posthog.capture("table_cell_edited", {
							path: row.path,
							value: newValue,
						})
						setEditMode(false)
						setCurrentlyEditingCell(false)
						onEdit && onEdit(newValue)
						setCellValue(newValue)
					}}
				/>
			) : (
				<div className="flex flex-row items-center justify-between">
					<span className={cashWarningTags}>{formattedValue}</span>
					{showAdditionalInfo && meaningfulAttachment && (
						<div
							onClick={(e) => {
								e.stopPropagation()
							}}
						>
							<CellInfoModal>
								<CellInfoModalTrigger asChild>
									{dataPoint.attachment !== undefined && (
										<HelpIcon className="ml-2 cashflow-table-cell-help-icon" />
									)}
								</CellInfoModalTrigger>
								<CellInfoModalContent
									datapoint={dataPoint}
									row={row}
									column={column}
									options={tableState.options}
								/>
							</CellInfoModal>
						</div>
					)}
				</div>
			)}
		</TableCell>
	)
}
