import { twMerge } from "tailwind-merge"



type TableProps = {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
} & React.HTMLProps<HTMLTableElement>

export const Table:React.FC<TableProps> = (props) => {
  const {
    className,
    children,
    ...rest
  } = props
  return (
    <div
      className="overflow-x-scroll overflow-y-scroll w-full h-full"
    >
      <table
        className={twMerge(
          `bg-white border-2 border-gray-200 rounded-lg`,
          className
        )}
        {...rest}
      >
        {children}
      </table>
    </div>
  )
}