import { Button } from "@/components/ui/button"
import { IntegrationStatus } from "../../types"
import { Loader2Icon, Unplug } from "lucide-react"
import trpc from "../../trpc"
import { useContext } from "react"
import { OrganizationContext } from "../../Contexts"
import _ from "lodash"

type IntegrationModalProps = {
	integrationStatus: IntegrationStatus[0]
	type: "Quickbooks"
}

export const IntegrationModalContent: React.FC<IntegrationModalProps> = ({
	integrationStatus,
	type,
}) => {
	const [organizationContext, setOrganizationContext] =
		useContext(OrganizationContext)

	const deleteIntegrationMutation =
		trpc.removeQuickbooksIntegration.useMutation({
			onSuccess: (data, { quickbooksIntegrationId }) => {
				const newOrganizationContext = _.cloneDeep(organizationContext)
				if (!newOrganizationContext.organization) {
					return
				}
				newOrganizationContext.integrationStatus =
					newOrganizationContext.integrationStatus.filter(
						(integrationStatus) =>
							integrationStatus.id !== quickbooksIntegrationId,
					)
				setOrganizationContext(newOrganizationContext)
			},
		})

	return (
		<div className="flex flex-col items-center justify-center space-y-4">
			<div>{integrationStatus.name}</div>
			{deleteIntegrationMutation.isError && (
				<div>{deleteIntegrationMutation.error.message}</div>
			)}
			{deleteIntegrationMutation.isSuccess && (
				<div>Successfully disconnected</div>
			)}
			{deleteIntegrationMutation.isPending && (
				<div>
					<Loader2Icon className="animate-spin text-gray-500" />
					Disconnecting...
				</div>
			)}
			{deleteIntegrationMutation.isIdle && (
				<div>
					<Button
						variant="destructive"
						onClick={() => {
							deleteIntegrationMutation.mutate({
								organizationId: organizationContext.organization!.id,
								quickbooksIntegrationId: integrationStatus.id,
							})
						}}
					>
						<Unplug />
						Disconnect
					</Button>
				</div>
			)}
		</div>
	)
}
