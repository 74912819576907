import { Currency } from "../types"

export const formatCurrency = (
  value: number,
  currency: Currency
): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  })
  const digitFormatted = formatter.format(value)
  if(digitFormatted.startsWith("-")) {
    return `(${digitFormatted.substring(1)})`
  }
  return digitFormatted
}