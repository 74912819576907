import { Frequency } from "../../../../../backend/src/BusinessLogic"
import { CurrentRatioLineChart } from "./CurrentRatioLineChart"
import {
	CurrentRatioKeyTakeaways,
	UnderstandingCurrentRatio,
	WhatTheCurrentRatioIndicates,
} from "./CurrentRatioText"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import MoreInfoSelector from "../../../Components/MoreInfoSelector"

type CurrentRatioSectionProps = {
	options: {
		startDate: Date
		endDate: Date
		frequency: Frequency
	}
}

export const CurrentRatioSection: React.FC<CurrentRatioSectionProps> = ({
	options,
}) => {
	const moreInfoOptions = [
		{
			trigger: <span className="font-bold">Understanding Current Ratio</span>,
			content: <UnderstandingCurrentRatio />,
		},
		{
			trigger: (
				<span className="font-bold">What the Current Ratio Indicates</span>
			),
			content: <WhatTheCurrentRatioIndicates />,
		},
		{
			trigger: <span className="font-bold">Current Ratio Key Takeaways</span>,
			content: <CurrentRatioKeyTakeaways />,
		},
	]

	return (
		<Card className="h-full">
			<CardHeader>
				<CardTitle>Current Ratio</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col items-center justify-center space-y-4 w-full">
					<CurrentRatioLineChart options={options} />

					<MoreInfoSelector options={moreInfoOptions} />
				</div>
			</CardContent>
		</Card>
	)
}
