import trpc from "../../trpc"
import { OrganizationContext } from "../../Contexts"
import { WorkingCapitalTableOptions } from "./types"
import { useCallback, useContext, useState } from "react"
import {
	Column,
	DataPoint,
	DataTable,
	DataTableRow,
	RowData,
} from "../DataTable"
import { TableBody, TableCell, TableHeader, TableRow } from "../Table"
import { NetChangeAttachment } from "../../../../backend/src/BusinessLogic/Attachments"
import { useWorkingCapitalTable } from "./useWorkingCapitalTable"

type WorkingCapitalTableProps = {
	options: WorkingCapitalTableOptions
	onRowOpen: (rowName: string) => void
	onRowClose: (rowName: string) => void
	className?: string
}

export const WorkingCapitalTable: React.FC<WorkingCapitalTableProps> = ({
	options,
	onRowOpen,
	onRowClose,
	className,
}) => {
	const [organizationContext] = useContext(OrganizationContext)
	const { table, isLoading, error, refetch } = useWorkingCapitalTable({
		tableOptions: options,
	})

	const currentlyEditingCell = useState(false)

	const tableState = {
		options,
		currentlyEditingCell,
	}

	const createAssertion = trpc.createAssertion.useMutation()

	const onCellEdit = useCallback(
		async (
			datapoint: DataPoint,
			row: RowData,
			column: Column,
			value: number,
		) => {
			const netChangeAttachment = containsNetChangeAttachment(datapoint)
			if (!organizationContext.organization) {
				console.error("No organization selected")
				return
			} else if (netChangeAttachment) {
				const oldValue = datapoint.value
				const changeInValue = value - oldValue
				const oldNetChange = netChangeAttachment.value
				const newNetChange = oldNetChange + changeInValue
				await createAssertion.mutateAsync({
					organizationId: organizationContext.organization.id,
					target: netChangeAttachment.netChangeTimeseriesPath,
					type: "Sum",
					startDate: netChangeAttachment.startDate,
					endDate: netChangeAttachment.endDate,
					value: newNetChange,
				})
			} else {
				await createAssertion.mutateAsync({
					organizationId: organizationContext.organization.id,
					target: row.path,
					type: "Sum",
					startDate: column.startDate,
					endDate: column.endDate,
					value: value,
				})
			}
			await refetch()
		},
		[organizationContext.organization, createAssertion, refetch],
	)

	return (
		<DataTable isLoading={isLoading} error={error ?? undefined}>
			<TableHeader>
				<TableRow className="border border-b-0">
					{table && (
						<>
							<TableCell sticky left>
								Date
							</TableCell>
							{table.columns.map((column, index) => (
								<TableCell key={index}>{column.name}</TableCell>
							))}
						</>
					)}
				</TableRow>
			</TableHeader>
			<TableBody>
				{table && (
					<>
						<DataTableRow
							tableState={tableState}
							row={table.cashRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.percentChangeInCashRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.ARBeginningBalanceRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.collectedARRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.newARRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.AREndBalanceRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.ARPercentChangeRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
								rowTags: "border-b-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.otherCurrentAssetsRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
							childRowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.currentAssetsRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-purple-100 border-l border-purple-500",
								rowTags: "bg-purple-100 border border-purple-500 border-l-0",
								cellTags: "hover:bg-purple-200",
								lastCellTags: "border-r-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.percentChangeInCurrentAssetsRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
							}}
						/>

						<TableRow className="h-4" />

						<DataTableRow
							tableState={tableState}
							row={table.APBeginningBalanceRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.collectedAPRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.newAPRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
							childRowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.APEndBalanceRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
						/>
						<DataTableRow
							tableState={tableState}
							row={table.APPercentChangeRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
								rowTags: "border-b-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.shortTermDebtRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
							childRowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.otherCurrentLiabilitiesRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-blue-100 border-l border-blue-500",
								rowTags: "bg-blue-100 border border-blue-500 border-l-0",
								cellTags: "hover:bg-blue-200",
								lastCellTags: "border-r-0",
							}}
							childRowStyle={{
								rowTags: "border-b-0 border-t-[1px]",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.currentLiabilitiesRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-purple-100 border-l border-purple-500",
								rowTags: "bg-purple-100 border border-purple-500 border-l-0",
								cellTags: "hover:bg-purple-200",
								lastCellTags: "border-r-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.percentChangeInCurrentLiabilitiesRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
							}}
						/>

						<TableRow className="h-4 border-b-0" />

						<DataTableRow
							tableState={tableState}
							row={table.netWorkCapitalRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								stickyOverlayTags: "bg-purple-200 border-l border-purple-600",
								rowTags: "bg-purple-200 border border-purple-600 border-l-0",
								cellTags: "hover:bg-purple-300",
								lastCellTags: "border-r-0",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.changeInNetWorkingCapital}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.percentChangeInNetWorkingCapital}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "italic",
								headerCellTags: "italic",
							}}
						/>

						<TableRow className="h-8" />

						<DataTableRow
							tableState={tableState}
							row={table.cogsRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "text-gray-500",
								headerCellTags: "text-gray-500",
							}}
						/>

						<DataTableRow
							tableState={tableState}
							row={table.creditSalesRow}
							columns={table.columns}
							onRowOpen={onRowOpen}
							onRowClose={onRowClose}
							onCellEdit={onCellEdit}
							rowStyle={{
								cellTags: "text-gray-500",
								headerCellTags: "text-gray-500",
							}}
						/>
					</>
				)}
			</TableBody>
		</DataTable>
	)
}

const containsNetChangeAttachment = (
	datapoint: DataPoint,
): NetChangeAttachment | null => {
	if (!datapoint.attachment) {
		return null
	}
	for (const attachment of datapoint.attachment) {
		if (!attachment) {
			continue
		}
		if (attachment.type === "NetChange") {
			return attachment
		}
	}

	return null
}
