import { calculateCCC } from "./calculateCCC"
import { calculateDIO } from "./calculateDIO"
import { calculateDPO } from "./calculateDPO"
import { calculateDSO } from "./calculateDSO"
import { WorkingCapitalTable } from "./types"

const valueIsAcceptable = (value: number): boolean => {
	if (isNaN(value)) {
		return false
	}
	if (value === Infinity) {
		return false
	}
	if (value === -Infinity) {
		return false
	}
	return true
}

export type WorkingCapitalMetricDatapoint = {
	time: string
	DSO?: number
	DPO?: number
	DIO?: number
	CCC?: number
}

/**
 * Calculates the working capital metrics for each period
 * except the first 6
 * The first 6 are not included because they are used
 * for filling in missing data
 *
 * @param table
 * @returns the working capital metrics for each period
 */
export const calculateWorkingCapitalMetrics = (
	table: WorkingCapitalTable,
): WorkingCapitalMetricDatapoint[] => {
	const output: WorkingCapitalMetricDatapoint[] = []
	const columns = table.columns

	const DSO = calculateDSO(table)
	const DPO = calculateDPO(table)
	const DIO = calculateDIO(table)
	const CCC = calculateCCC(DIO, DSO, DPO)

	for (let i = 6; i < columns.length; i++) {
		const column = columns[i]
		const DSO_value = DSO[i - 6].DSO
		const DPO_value = DPO[i - 6].DPO
		const DIO_value = DIO[i - 6].DIO
		const CCC_value = CCC[i - 6].CCC

		const datapoint: WorkingCapitalMetricDatapoint = {
			time: column.name,
		}

		if (valueIsAcceptable(DSO_value)) {
			datapoint.DSO = DSO_value
		}
		if (valueIsAcceptable(DPO_value)) {
			datapoint.DPO = DPO_value
		}
		if (valueIsAcceptable(DIO_value)) {
			datapoint.DIO = DIO_value
		}
		if (valueIsAcceptable(CCC_value)) {
			datapoint.CCC = CCC_value
		}

		output.push(datapoint)
	}
	return output
}
