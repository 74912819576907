import { LineChartMultipleDataType } from "../../../Components/ShadcnPlainComponents"
import { calculateWorkingCapitalMetrics } from "../../../Components/WorkingCapitalTable"
import { Loader2Icon } from "lucide-react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"
import {
	ChartConfig,
	ChartContainer,
	ChartLegend,
	ChartLegendContent,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart"
import { moveDateBack } from "../../../utils"
import { useTrailingWorkingCapital } from "../../../hooks"
import { useWorkingCapitalTable } from "../../../Components/WorkingCapitalTable/useWorkingCapitalTable"

type WorkingCapitalMetricsLineChartOptions = {
	options: {
		startDate: Date
		endDate: Date
	}
}

export const WorkingCapitalMetricsLineChart: React.FC<
	WorkingCapitalMetricsLineChartOptions
> = ({ options }) => {
	//subtract 6 time periods from the start date
	//the extra data is used for filling in missing data
	const startDateToUse = moveDateBack(options.startDate, "monthly", 6)

	const { table, isLoading, error } = useWorkingCapitalTable({
		tableOptions: {
			startDate: startDateToUse,
			endDate: options.endDate,
			frequency: "monthly",
		},
	})

	const {
		value: trailingValue,
		isLoading: isLoadingTrailing,
		error: errorTrailing,
	} = useTrailingWorkingCapital({
		date: options.endDate,
		trailingPeriods: 13,
		frequency: "weekly",
	})

	if (error) {
		return (
			<div>
				<span className="text-red-500">Error loading data: {error}</span>
			</div>
		)
	}

	if (!table) {
		return <div>No organization selected.</div>
	}

	if (errorTrailing) {
		return (
			<div>
				<span className="text-red-500">
					Error loading trailing data: {errorTrailing}
				</span>
			</div>
		)
	}

	if (
		isLoading ||
		isLoadingTrailing ||
		table === null ||
		trailingValue === null
	) {
		if (!isLoading) {
		}
		return (
			<div>
				<Loader2Icon className="animate-spin text-gray-500" />
			</div>
		)
	}

	let chartData: LineChartMultipleDataType[] =
		calculateWorkingCapitalMetrics(table)

	const chartConfig = {
		DSO: {
			label: "DSO",
			color: "#4285F4",
		},
		DPO: {
			label: "DPO",
			color: "#FF9500",
		},
		DIO: {
			label: "DIO",
			color: "#34C759",
		},
		CCC: {
			label: "CCC",
			color: "#c084fc",
		},
	} satisfies ChartConfig

	return (
		<div className="w-full h-full p-4 border border-gray-200 rounded-lg pl-0 flex flex-col justify-start items-center">
			<div className="w-full h-full">
				<ChartContainer config={chartConfig}>
					<LineChart accessibilityLayer data={chartData}>
						<CartesianGrid vertical={false} />
						<XAxis
							dataKey="time"
							tickLine={false}
							axisLine={false}
							tickMargin={8}
							tickFormatter={(value) => value.slice(0, 3)}
						/>
						<YAxis
							label={{
								value: "# of Days",
								angle: -90,
								position: "insideLeft",
								textAnchor: "middle",
							}}
							tickMargin={8}
						/>
						<ChartTooltip cursor={false} content={<ChartTooltipContent />} />
						<Line
							dataKey="DSO"
							type="monotone"
							stroke="#4285F4"
							strokeWidth={2}
							dot={false}
						/>
						<Line
							dataKey="DPO"
							type="monotone"
							stroke="#FF9500"
							strokeWidth={2}
							dot={false}
						/>
						<Line
							dataKey="DIO"
							type="monotone"
							stroke="#34C759"
							strokeWidth={2}
							dot={false}
						/>
						<Line
							dataKey="CCC"
							type="monotone"
							stroke="#c084fc"
							strokeWidth={4}
							dot={false}
						/>
						<ChartLegend content={<ChartLegendContent />} />
					</LineChart>
				</ChartContainer>
				<div className="flex flex-row items-center justify-center space-x-2 text-gray-500">
					<span>13 week trailing CCC:</span>
					<span className="font-bold text-purple-500">
						{trailingValue.toFixed(2)}
					</span>
				</div>
			</div>
		</div>
	)
}
