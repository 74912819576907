import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

export const ImplementationTipsText = () => {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Implementation Tips</CardTitle>
			</CardHeader>
			<CardContent>
				<ul className="list-disc list-inside">
					<li>
						<strong>Start Small: </strong>
						Begin with small changes and gradually implement more comprehensive
						strategies.
					</li>
					<li>
						<strong>Continuous Improvement: </strong>
						Regularly review and adjust strategies to adapt to changing business
						conditions.
					</li>
					<li>
						<strong>Cross-Functional Collaboration: </strong>
						Involve different departments in working capital management
						initiatives to ensure holistic improvements.
					</li>
				</ul>
			</CardContent>
		</Card>
	)
}
