import { useState } from "react"
import { SettingOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Menu } from "antd"
import { UserButton } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom"
import { ChartNoAxesCombined, ListChecks, Waypoints } from "lucide-react"

type MenuItem = Required<MenuProps>["items"][number]

const iconClassName = "h-6 w-6 text-gray-500"

const items: MenuItem[] = [
	{
		label: "Dashboard",
		key: "dashboard",
		icon: <ChartNoAxesCombined className={iconClassName} />,
	},
	{
		label: "My Integrations",
		key: "integrations",
		icon: <Waypoints className={iconClassName} />,
	},
	{
		label: "Onboard Company",
		key: "onboard-company",
		icon: <ListChecks className={iconClassName} />,
	},
	{
		label: "My Cashflow",
		key: "my-cashflow",
		icon: <SettingOutlined />,
		disabled: true,
	},
	{
		label: "Forecasting",
		key: "forecasting",
		icon: <SettingOutlined />,
		disabled: true,
	},
	{
		label: "Reports",
		key: "reports",
		icon: <SettingOutlined />,
		disabled: true,
	},
]

const NavDrawer = () => {
	const navigate = useNavigate()
	const [current, setCurrent] = useState("mail")

	const onClick: MenuProps["onClick"] = (e) => {
		setCurrent(e.key)
		switch (e.key) {
			case "dashboard":
				navigate("/")
				break
			case "onboard-company":
				navigate("/onboarding")
				break
			case "my-cashflow":
				navigate("/cashflow")
				break
			case "integrations":
				navigate("/integrations")
				break
			default:
				console.error(`Unknown menu item ${e.key}`)
				break
		}
	}

	return (
		<div>
			<div
				className={`
          h-screen w-[16rem] z-50
          bg-white shadow-md
          overflow-y-auto transition duration-300
        `}
			>
				<div className="flex flex-col items-center justify-center p-4 text-center mt-16">
					<UserButton signInUrl="/sign-in" />
					<span> My Account </span>
				</div>
				<Menu
					onClick={onClick}
					selectedKeys={[current]}
					mode="inline"
					items={items}
				/>
			</div>
		</div>
	)
}

export default NavDrawer
