import { ClerkProvider } from "@clerk/clerk-react"
import { Outlet, useNavigate } from "react-router-dom"
import { PostHogProvider } from "posthog-js/react"

const PostHogOptions = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!CLERK_PUBLISHABLE_KEY) {
	console.error("Missing Clerk Publishable Key")
}

const RootLayout: React.FC = () => {
	const navigate = useNavigate()

	if (!CLERK_PUBLISHABLE_KEY) return <div>Missing Clerk Publishable Key</div>

	return (
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={PostHogOptions}
		>
			<ClerkProvider
				routerPush={(to) => navigate(to)}
				routerReplace={(to) => navigate(to, { replace: true })}
				publishableKey={CLERK_PUBLISHABLE_KEY}
			>
				<div className="root-layout">
					<Outlet />
				</div>
			</ClerkProvider>
		</PostHogProvider>
	)
}

export default RootLayout
