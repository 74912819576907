
const PrivacyPolicy = () => <>
  <div
    className="w-screen h-screen"
  >
    <iframe
      title="Privacy Policy"
      src="/PrivacyPolicy.html"
      width="100%"
      height="100%"
    />
  </div>
</>

export default PrivacyPolicy