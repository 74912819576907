import { useState } from "react"
import { QuickbooksCashAttachment } from "../../../../../backend/src/Quickbooks"
import { ToggleChevron } from "../../ToggleChevron"
import { DataPoint } from "../../DataTable"

type QuickbooksCashAttachmentViewProps = {
  cashAttachment: QuickbooksCashAttachment
  datapoint: DataPoint
}

const today = new Date()
today.setHours(0, 0, 0, 0)

export const QuickbooksCashAttachmentView:React.FC<QuickbooksCashAttachmentViewProps> = ({
  cashAttachment,
  datapoint
}) => {
  const [expanded, setExpanded] = useState(false)
  const todayString = today.toLocaleDateString()
  return (
    <div className="flex flex-col items-start ">
      <span>Your cash on hand as of {todayString}</span>
      <div className="flex flex-row items-center justify-between space-x-1 flex-wrap w-full">
        <span>Cash: </span>
        <span
          className="text-purple-400"
        >
          {cashAttachment.cash}
        </span>
      </div>
      <div className="flex flex-row items-center justify-start space-x-1 flex-wrap">
        <span>By Account:</span>
        <ToggleChevron
          isOpen={expanded}
          onClick={
            ()=>setExpanded(!expanded)
          }
        />
      </div>
      {expanded &&
        cashAttachment.accounts.map((account, index) => (
          <div
            className="flex flex-row items-center justify-between space-x-1 flex-wrap w-full"
            key={index}
          >
            <span>{account.Name}</span>
            <span
              className="text-purple-400"
            >
              {account.CurrentBalance}
            </span>
          </div>
        ))
      }
    </div>
  )
}