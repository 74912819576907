import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { posthog } from "posthog-js"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

type DatePickerProps = {
  id?: string
  onSelect?: (date: Date) => void
  defaultDate?: Date
}

export const DatePickerDemo:React.FC<DatePickerProps> = ({
  id,
  onSelect,
  defaultDate,
}) => {
  const [date, setDate] = React.useState<Date>(defaultDate || new Date())

  const handleSelect = (date: Date|undefined) => {
    if(!date) {
      return
    }
    setDate(date)
    onSelect && onSelect(date)
    posthog.capture("date_picker_selected", { date })
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id={id}
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
