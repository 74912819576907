import { useContext } from "react"
import { OrganizationContext } from "../../../Contexts"
import trpc from "../../../trpc"
import {
	InvoiceAttachment,
	ReasonForInclusion,
	Invoice,
} from "../../../../../backend/src/Quickbooks"

type ContactCustomerViewProps = {
	invoice: Invoice
}

const ContactCustomerView: React.FC<ContactCustomerViewProps> = ({
	invoice,
}) => {
	return (
		<div className="flex flex-row items-center justify-start space-x-1 space-y-1 flex-wrap">
			<span>Contact Customer </span>
			<span>{invoice.CustomerRef.name}</span>
			{invoice.BillEmail && (
				<span>
					<a
						href={`mailto:${invoice.BillEmail.Address}`}
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 underline"
					>
						{invoice.BillEmail.Address}
					</a>
				</span>
			)}
		</div>
	)
}

type ReasonForInclusionViewProps = {
	reasonForInclusion: ReasonForInclusion
}

const ReasonForInclusionView: React.FC<ReasonForInclusionViewProps> = ({
	reasonForInclusion,
}) => {
	if (reasonForInclusion.reason === "Due") {
		return <span>Invoice is due</span>
	}
	if (reasonForInclusion.reason === "Overdue") {
		return (
			<>
				<span className="text-red-500">Invoice is overdue</span>
			</>
		)
	}
	if (reasonForInclusion.reason === "Payment Made") {
		return <span>Payment made</span>
	}
	return <span>Unknown reason for inclusion</span>
}

type InvoiceViewProps = {
	invoiceAttachment: InvoiceAttachment
	className?: string
}

export const InvoiceView: React.FC<InvoiceViewProps> = ({
	invoiceAttachment,
	className,
}) => {
	const [organizationContext] = useContext(OrganizationContext)
	const { data, isLoading, error } = trpc.getInvoice.useQuery(
		{
			organizationId: organizationContext.organization?.id || -1,
			invoiceId: invoiceAttachment.InvoiceRef.value,
		},
		{
			enabled: !!organizationContext.organization,
			suspense: true,
		},
	)

	if (error) {
		return <p>Error: {error.message}</p>
	}
	if (isLoading) {
		return <p>Loading...</p>
	}
	if (!data) {
		return <p>No data</p>
	}
	const { result: invoice, error: invoiceError } = data
	if (invoiceError) {
		return <p>Error: {invoiceError.message}</p>
	}

	const overdue = invoiceAttachment.reasonForInclusion.reason === "Overdue"

	return (
		<div className="flex flex-col items-start">
			{invoice.CustomerRef?.name && <span>{invoice.CustomerRef.name}</span>}
			<div className="flex flex-row items-start space-x-1">
				<span>Total: </span>
				<span>{invoice.TotalAmt!}</span>
			</div>
			<div className="flex flex-row items-start space-x-1">
				<span>Balance Due: </span>
				<span>{invoice.Balance!}</span>
			</div>
			<div>
				<span>Due Date: </span>
				<span className={overdue ? "text-red-500" : "text-gray-500"}>
					{invoice.DueDate!}
				</span>
			</div>
			<div>
				<span>Reason for Inclusion: </span>
				<ReasonForInclusionView
					reasonForInclusion={invoiceAttachment.reasonForInclusion}
				/>
			</div>
			<ContactCustomerView invoice={invoice} />
		</div>
	)
}
