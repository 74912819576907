import { useState } from "react"
import { WorkingCapitalTable } from "./WorkingCapitalTable"
import { StartEndFrequencySelector } from "../StartEndFrequencySelector"
import { Frequency } from "../../../../backend/src/BusinessLogic"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"

const today = new Date()
today.setHours(0, 0, 0, 0)

const fourWeeksAgo = new Date(today)
fourWeeksAgo.setDate(today.getDate() - 28)

const DEFAULT_FREQUENCY: Frequency = "weekly"

type WorkingCapitalTableBuilderProps = {
	selectedStartEndFrequency?: {
		startDate: Date
		endDate: Date
		frequency: Frequency
	}
}

/**
 * This component will build the working capital table.
 * It will allow the user to select a start and end date, and frequency.
 * It will then use the selected start and end date, frequency, and open rows to build the working capital table.
 *
 * When the table is edited, the table data is refetched to reflect the changes.
 */
export const WorkingCapitalTableBuilder: React.FC<
	WorkingCapitalTableBuilderProps
> = ({ selectedStartEndFrequency }) => {
	const hasSelectedStartEndFrequency = selectedStartEndFrequency !== undefined

	const [startDate, setStartDate] = useState<Date>(fourWeeksAgo)
	const [endDate, setEndDate] = useState<Date>(today)
	const [frequency, setFrequency] = useState<Frequency>(DEFAULT_FREQUENCY)
	const [cashWarningThreshold, setCashWarningThreshold] =
		useState<number>(10000)

	const [openRows, setOpenRows] = useState<string[]>([
		"working_capital_model/other_current_assets",
		"working_capital_model/short_term_debt",
		"working_capital_model/other_current_liabilities",
	])

	return (
		<div className="flex flex-col items-center justify-start space-y-4 w-full flex-wrap">
			<Card className="min-w-[300px]">
				<CardHeader>
					<CardTitle>Working Capital Table</CardTitle>
				</CardHeader>
				<CardContent className="space-y-4">
					<div className="w-fit space-y-2">
						<span className="text-gray-500">About Table</span>
						<p>
							This table shows the working capital of your business. It is a
							comprehensive view of your business's financial health.
						</p>
						<p>
							Any edits you make to the table will be stored and encourperated
							into your cashflow model.
						</p>
					</div>
					{!hasSelectedStartEndFrequency && (
						<StartEndFrequencySelector
							startDate={startDate}
							endDate={endDate}
							frequency={frequency}
							onStartDateChange={setStartDate}
							onEndDateChange={setEndDate}
							onFrequencyChange={setFrequency}
						/>
					)}
					<div className="flex flex-row items-center justify-center space-x-2">
						<span>Cash should never go below</span>
						<Input
							className="w-24"
							type="number"
							value={cashWarningThreshold}
							onChange={(e) => setCashWarningThreshold(Number(e.target.value))}
						/>
					</div>
				</CardContent>
			</Card>
			<div className="p-8 w-fit max-w-full">
				<WorkingCapitalTable
					options={{
						startDate,
						endDate,
						currency: "USD",
						frequency: frequency.toLowerCase() as any,
						openRows,
						warnings: [
							{
								path: "working_capital_model/net_working_capital/current_assets/net_change_in_cash",
								threshold: cashWarningThreshold,
								triggerBelow: true,
								colorTag: "text-red-500",
							},
						],
						...selectedStartEndFrequency,
					}}
					onRowOpen={(rowPath) => {
						setOpenRows([...openRows, rowPath])
					}}
					onRowClose={(rowPath) => {
						setOpenRows(openRows.filter((row) => row !== rowPath))
					}}
				/>
			</div>
		</div>
	)
}
