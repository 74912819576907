import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { MarkdownRenderer } from "Components/MarkdownRenderer"

export type AccordionMarkdownDataType = {
	title: string
	markdownContent: string // Markdown content as string
}

interface AccordionMarkdownComponentProps {
	data: AccordionMarkdownDataType[]
}

export function AccordionComponentMarkdown({
	data,
}: AccordionMarkdownComponentProps) {
	return (
		<Accordion type="single" collapsible className="w-full">
			{data.map((accordionData, index) => (
				<AccordionItem key={index} value={accordionData.title}>
					<AccordionTrigger>{accordionData.title}</AccordionTrigger>
					<AccordionContent>
						<MarkdownRenderer markdown={accordionData.markdownContent} />
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	)
}
