import { HelpCircleIcon } from "lucide-react"
import React from "react"
import { twMerge } from "tailwind-merge"

type HelpIconProps = {
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

export const HelpIcon:React.FC<HelpIconProps> = ({
  className,
  onClick
}) => {
  const mergedClassName = twMerge("h-4 w-4 cursor-pointer text-gray-500", className)
  return (
    <HelpCircleIcon
      className={mergedClassName}
      onClick={onClick}
    />
  )
}