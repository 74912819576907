import { AccordionDataType } from "../../../Components/ShadcnPlainComponents"
import { AccordionComponent } from "../../../Components/ShadcnPlainComponents"

export const TheMetricsText = () => {
	const PropsForAccordion: AccordionDataType[] = [
		{
			AccordionTitle: "Days Inventory Outstanding (DIO)",
			AccordionSummary:
				"DIO measures the average number of days a company takes to turn its inventory into sales.",
			subsections: [
				{
					title: "Increase in DIO",
					contents: [
						"Explanation: An increase in DIO indicates that inventory is taking longer to sell.",
						"Impact on Liquidity: This can lead to higher holding costs and tie up cash in inventory, " +
							"reducing liquidity. The company may face cash flow issues if it cannot convert inventory to cash quickly.",
					],
				},
				{
					title: "Decrease in DIO",
					contents: [
						"Explanation: A decrease in DIO indicates that inventory is selling faster.",
						"Impact on Liquidity: This can improve cash flow and liquidity as inventory is converted to cash more quickly.",
					],
				},
			],
		},
		{
			AccordionTitle: "Days Sales Outstanding (DSO)",
			AccordionSummary:
				"DSO measures the average number of days a company takes to collect payment after a sale.",
			subsections: [
				{
					title: "Increase in DSO",
					contents: [
						"Explanation: An increase in DSO indicates that customers are taking longer to pay their invoices.",
						"Impact on Liquidity: This delays cash inflows, reducing liquidity. " +
							"The company may need to find alternative financing to cover operating expenses during this period.",
					],
				},
				{
					title: "Decrease in DSO",
					contents: [
						"Explanation: A decrease in DSO indicates that customers are paying faster.",
						"Impact on Liquidity: This can improve cash flow and liquidity as cash is collected more quickly.",
					],
				},
			],
		},
		{
			AccordionTitle: "Cash Conversion Cycle (CCC)",
			AccordionSummary:
				"CCC measures the time it takes for a company to convert its investments in inventory and other resources into cash flows from sales. It is calculated as: CCC=DIO+DSO−DPO",
			subsections: [
				{
					title: "Increase in CCC",
					contents: [
						"Explanation: An increase in CCC indicates that the company is taking longer to convert investments into cash flows.",
						"Impact on Liquidity: This can negatively impact liquidity, as more cash is tied up in operations. " +
							"The company may need to rely on external financing to meet short-term obligations.",
					],
				},
				{
					title: "Decrease in CCC",
					contents: [
						"Explanation: A decrease in CCC means the company is converting its investments into cash more quickly.",
						"Impact on Liquidity: Improved cash conversion enhances liquidity, " +
							"reducing the need for external financing and allowing the company to reinvest in its operations or pay down debt.",
					],
				},
			],
		},
	]

	return (
		<div className="w-full">
			<h2 className="text-2xl font-bold mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
				The Metrics
			</h2>
			<p>
				Understanding the dynamics of key working capital metrics—Days Inventory
				Outstanding (DIO), Days Sales Outstanding (DSO), Days Payable
				Outstanding (DPO), and Cash Conversion Cycle (CCC)—is essential for
				managing liquidity. Here's an explanation of how changes in these
				metrics affect a company's liquidity
			</p>
			<AccordionComponent data={PropsForAccordion} />
		</div>
	)
}

export const KeyTakeaways = () => {
	return (
		<div className="w-full">
			<h2 className="text-2xl font-bold mb-4 whitespace-nowrap overflow-hidden text-ellipsis">
				Key Takeaways
			</h2>
			<ul className="list-disc list-inside">
				<li>
					<span>
						<strong>DIO</strong>:
					</span>
					A lower DIO improves liquidity by freeing up cash faster.
				</li>
				<li>
					<span>
						<strong>DSO</strong>:
					</span>
					A lower DSO enhances liquidity by accelerating cash inflows.
				</li>
				<li>
					<span>
						<strong>DPO</strong>:
					</span>
					A higher DPO improves liquidity by retaining cash longer, but should
					be balanced to maintain good supplier relationships.
				</li>
				<li>
					<span>
						<strong>CCC</strong>:
					</span>
					A lower CCC indicates a more efficient cash conversion process,
					enhancing overall liquidity.
				</li>
			</ul>
		</div>
	)
}
